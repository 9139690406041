import React from 'react';
import PropTypes from 'prop-types';
import { IonText, IonGrid,IonRow,IonCol, IonLabel, IonSegment, IonSegmentButton  } from '@ionic/react';
import Time from '../components/Time';
import './TimeTable.scss';
let labels = require('./TimeTable.json');

class TimeTable extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    program: PropTypes.object,
    secondsPasted: PropTypes.number
  };
  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  constructor(props: any) {
    super(props);

    this.state = {
      segment : 'absolute'    // absolute || relative
    };

    this.onChangeSegment = this.onChangeSegment.bind(this);
  }

  componentDidMount() {
    if(this.props.secondsPasted!==undefined)
      this.setState({
        segment : 'relative'
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.secondsPasted===undefined && this.props.secondsPasted!==undefined){
      this.setState({
        segment : 'relative'
      });
    }
  }

  onChangeSegment(e){
    if(this.props.isLogDebug) console.log('-- TimeTable.tsx -- onChangeSegment()',e.detail.value);
    this.setState({
      segment: e.detail.value
    });
  }


  render() {

    if(this.props.secondsPasted===undefined)
      return (
        <div className="ion-padding-vertical">
          {this.renderAbsolute()}
        </div>
      );

    return (
      <div className="ion-padding-vertical">
        <IonSegment onIonChange={this.onChangeSegment}>
          <IonSegmentButton value="absolute" checked={this.state.segment==='absolute'}>
            <IonLabel>{labels[this.props.locale]['ABSOLUTE']}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="relative" checked={this.state.segment==='relative'}>
            <IonLabel>{labels[this.props.locale]['RELATIVE']}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        { this.state.segment === 'absolute' ? this.renderAbsolute() : this.renderRelative() }
      </div>
    );
  }

  renderAbsolute() {
    return (
      <IonGrid className="time-table">
        <IonRow>
          <IonCol className="ion-text-center ion-no-padding col-index">
            
          </IonCol>
          <IonCol className="ion-text-start">
            <IonLabel><strong>{labels[this.props.locale]['STEP']}</strong></IonLabel>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonLabel><strong>{labels[this.props.locale]['DURATION']}</strong></IonLabel>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonLabel>
              <strong>{labels[this.props.locale]['STARTAT']}</strong>
              <br/><small>{labels[this.props.locale]['STARTAT_SUB_ABSOLUTE']}</small>
            </IonLabel>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonLabel>
              <strong>{labels[this.props.locale]['ENDAT']}</strong>
              <br/><small>{labels[this.props.locale]['ENDAT_SUB_ABSOLUTE']}</small>
            </IonLabel>
          </IonCol>
        </IonRow>
        {this.props.program.steps.map((step,index) => {
          let isPasted = this.props.secondsPasted!==undefined && this.props.secondsPasted >= (step.endsAt * 60);
          let isCurrent = this.props.secondsPasted!==undefined && this.props.secondsPasted > (step.beginsAt * 60) && this.props.secondsPasted < (step.endsAt * 60);

          let display = isPasted ? ' lowlight' : (isCurrent ? ' highlight' : null);
          let secondaryColor = isPasted ? '' : (isCurrent ? 'primary' : 'primary');
          return (
            <IonRow key={index} className={display}>
              <IonCol className="ion-text-center ion-align-self-center ion-no-padding col-index">
                <div className="bullet-basic">{step.index}</div>
              </IonCol>
              <IonCol className="ion-text-start ion-align-self-center ">
                <IonLabel className="step-name">{labels[this.props.locale][step.id]}</IonLabel>
              </IonCol>
              <IonCol className="ion-text-center ion-align-self-center ">
                <IonLabel><Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={step.value} size="small"/></IonLabel>
              </IonCol>
              <IonCol className="ion-text-center ion-align-self-center ">
                <IonLabel>
                  <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={step.beginsAt} size="small"/>
                </IonLabel>
              </IonCol>
              <IonCol className="ion-text-center ion-align-self-center ">
                <IonLabel>
                  <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={step.endsAt} size="small"/>
                </IonLabel>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>
    );
  }

  renderRelative(){
    return (
      <IonGrid className="time-table">
        <IonRow>
          <IonCol className="ion-text-center ion-no-padding col-index">
            
          </IonCol>
          <IonCol className="ion-text-start">
            <IonLabel><strong>{labels[this.props.locale]['STEP']}</strong></IonLabel>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonLabel><strong>{labels[this.props.locale]['DURATION']}</strong></IonLabel>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonLabel>
              <strong>{labels[this.props.locale]['STARTAT']}</strong>
              <br/><small>{labels[this.props.locale]['STARTAT_SUB_RELATIVE']}</small>
            </IonLabel>
          </IonCol>
          <IonCol className="ion-text-center">
            <IonLabel>
              <strong>{labels[this.props.locale]['ENDAT']}</strong>
              <br/><small>{labels[this.props.locale]['ENDAT_SUB_RELATIVE']}</small>
            </IonLabel>
          </IonCol>
        </IonRow>
        {this.props.program.steps.map((step,index) => {
          let isPasted = this.props.secondsPasted!==undefined && this.props.secondsPasted >= (step.endsAt * 60);
          let isCurrent = this.props.secondsPasted!==undefined && this.props.secondsPasted > (step.beginsAt * 60) && this.props.secondsPasted < (step.endsAt * 60);

          let display = isPasted ? ' lowlight' : (isCurrent ? ' highlight' : null);
          let secondaryColor = isPasted ? '' : (isCurrent ? 'danger' : 'primary');

          return (
            <IonRow key={index} className={display}>
              <IonCol className="ion-text-center ion-align-self-center ion-no-padding col-index">
                <div className="bullet-basic">{step.index}</div>
              </IonCol>
              <IonCol className="ion-text-start ion-align-self-center ">
                <IonLabel className="step-name">{labels[this.props.locale][step.id]}</IonLabel>
              </IonCol>
              <IonCol className="ion-text-center ion-align-self-center ">
                <IonLabel><Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={step.value} size="small"/></IonLabel>
              </IonCol>
              <IonCol className="ion-text-center ion-align-self-center ">
                <IonLabel>
                  <IonText color={secondaryColor}><Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} seconds={step.beginsAt*60 - this.props.secondsPasted} size="small"/></IonText>
                </IonLabel>
              </IonCol>
              <IonCol className="ion-text-center ion-align-self-center ">
                <IonLabel>
                  <IonText color={secondaryColor}><Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} seconds={step.endsAt*60 - this.props.secondsPasted} size="small"/></IonText>
                </IonLabel>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>
    );
  }
}

export default TimeTable;
