import React from 'react';
import PropTypes from 'prop-types';
import { IonIcon, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonLabel,IonCardTitle  } from '@ionic/react';
import { bookmark } from 'ionicons/icons';
import Time from '../components/Time';
import CardFabs from '../components/CardFabs';
import './Card.scss';

let labels = require('./Card.json');

class Card extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    program: PropTypes.object,
    isHidden: PropTypes.bool,
    isPined: PropTypes.bool,
    isCountdownDisabled: PropTypes.bool,
    highlightedProgramId: PropTypes.string,
    headerHeight: PropTypes.number,
    scrollTop: PropTypes.number,
    onTogglePined: PropTypes.func,
    onShowDetails: PropTypes.func,
    onCreateCountdown: PropTypes.func,
    onHighlight: PropTypes.func,
    onUnHighlight: PropTypes.func,
    onShare: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  private _scene = React.createRef<any>();
  private _left = undefined;
  private _top = undefined;
  private _event = undefined;
  private _lasttouch = undefined;
  private _timeout = undefined;
  private LONGTOUCHDELAY = 700;

  constructor(props: any) {
    super(props);

    this.state = {
      isFlipped: false
    };

    this.flipToBack = this.flipToBack.bind(this);
    this.flipToFront = this.flipToFront.bind(this);
    this.togglePined = this.togglePined.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.onCreateCountdown = this.onCreateCountdown.bind(this);

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.handleFabClick = this.handleFabClick.bind(this);
    this.renderFabs = this.renderFabs.bind(this);
  }

  flipToBack(){
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    if(!this.state.isFlipped)
      this.setState((state) => ({
        isFlipped : true
      }));
  }
  flipToFront(){
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    if(this.state.isFlipped)
      this.setState((state) => ({
        isFlipped : false
      }));
  }

  togglePined(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    e.preventDefault();
    e.stopPropagation();
    this.props.onTogglePined();
  }

  showDetails(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    e.preventDefault();
    e.stopPropagation();
    this.props.onShowDetails(this.props.program);
  }

  onCreateCountdown(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    e.preventDefault();
    e.stopPropagation();
    this.props.onCreateCountdown(this.props.program);
  }




  handleTouchStart(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    e.preventDefault();
    if(e.targetTouches && e.targetTouches.length>0){
      this._left = e.targetTouches[0].clientX;
      this._top = e.targetTouches[0].clientY;
      this._lasttouch = (new Date()).getTime();
      var self = this;
      this._timeout = setTimeout(function(){
        // if(self.props.isLogDebug) console.log('-- Card.tsx -- handleTouchStart() long click',self._left,self._top,self._lasttouch);
        self._event = 'longclick';
        if(self.props.onHighlight)
          self.props.onHighlight(self.props.program);
      },this.LONGTOUCHDELAY);
      // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleTouchStart() ',this._left,this._top,this._lasttouch);
    }
  }
  handleMouseDown(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleMouseDown() ',e.button);
    if(e.button !== 0)
      return;
    e.preventDefault();
    this._left = e.clientX;
    this._top = e.clientY;
    this._lasttouch = (new Date()).getTime();
    var self = this;
    this._timeout = setTimeout(function(){
      // if(self.props.isLogDebug) console.log('-- Card.tsx -- handleMouseDown() long click',self._left,self._top,self._lasttouch);
      self._event = 'longclick';
      if(self.props.onHighlight)
        self.props.onHighlight(self.props.program);
    },this.LONGTOUCHDELAY);
    // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleMouseDown() ',this._left,this._top,this._lasttouch);
  }
  
  handleTouchMove(e, isFront) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    if(!this._left || this._event)
      return;
    if(e.targetTouches && e.targetTouches.length>0){
      let newLeft = e.targetTouches[0].clientX;
      if(isFront){
        // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleTouchMove() ','isFront',newLeft,'<?',this._left - 50,newLeft < (this._left - 50));
        if(newLeft < (this._left - 200)){
          this.flipToBack();
          this._event = 'flip';
          clearTimeout(this._timeout);
        }
      }else{
        // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleTouchMove() ','isBack',newLeft,'>?',this._left + 50);
        if(newLeft > (this._left + 200)){
          this.flipToFront();
          this._event = 'flip';
          clearTimeout(this._timeout);
        }
      }
    }
  }
  handleMouseMove(e, isFront) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    if(!this._left || this._event)
      return;
    let newLeft = e.clientX;
    if(isFront){
      // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleMouseMove() ','isFront',newLeft,'<?',this._left - 50,newLeft < (this._left - 50));
      if(newLeft < (this._left - 200)){
        this.flipToBack();
        this._event = 'flip';
        clearTimeout(this._timeout);
      }
    }else{
      // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleMouseMove() ','isBack',newLeft,'>?',this._left + 50);
      if(newLeft > (this._left + 200)){
        this.flipToFront();
        this._event = 'flip';
        clearTimeout(this._timeout);
      }
    }
  }
  
  handleTouchEnd(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    if(!this._left)
      return;
    if(e.targetTouches && e.targetTouches.length>0){
      let newLeft = e.targetTouches[0].clientX;
      let newTouch = (new Date()).getTime();
      // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleTouchEnd() ',newLeft,newTouch);
      if(!this._event){
        // on a pas fait de flip, on regarde si c un click
        if(newLeft === this._left){
          if(newTouch < (this._lasttouch + this.LONGTOUCHDELAY)){
            // short click
            this.showDetails(e);
            this._event = 'click';
          }
        }
      }
    }
    this._left = undefined;
    this._top = undefined;
    this._event = undefined;
    this._lasttouch = undefined;
    clearTimeout(this._timeout);
    // if(this.props.onUnHighlight)
    //   this.props.onUnHighlight();
  }
  handleMouseUp(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    if(!this._left)
      return;
    let newLeft = e.clientX;
    let newTouch = (new Date()).getTime();
    clearTimeout(this._timeout);
    // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleMouseUp() ',newLeft,newTouch);
    if(!this._event){
      // on a pas fait de flip, on regarde si c un click
      if(newLeft === this._left){
        if(newTouch < (this._lasttouch + this.LONGTOUCHDELAY)){
          // short click
          this.showDetails(e);
          this._event = 'click';
        }
      }
    }
    this._left = undefined;
    this._top = undefined;
    this._event = undefined;
    this._lasttouch = undefined;
    clearTimeout(this._timeout);
    // if(this.props.onUnHighlight)
    //   this.props.onUnHighlight();
  }
  handleMouseLeave(e) {
    if(this.props.highlightedProgramId && this.props.highlightedProgramId!==this.props.program.id)
      return;
    // if(this.props.isLogDebug) console.log('-- Card.tsx -- handleMouseLeave() ',e);
    this.handleMouseUp(e);
  }



  handleFabClick(e,cb){
    e.preventDefault();
    if(cb)
      cb();
  }



  renderFabs() {
    if(!this.props.highlightedProgramId || this.props.highlightedProgramId !== this.props.program.id)
      return null;
    if(!this._scene || !this._scene.current)
      return null;

    // le top est relatif a la scene
    // on veut la position du click par rapport a la scene
    // this._top est relatif a la fenetre : il faut enlever la hauteur visible du header
    // this._scene.current.offsetTop est la position de la scene par rapoort a ion-content
    // 
    let top = this._top - this.props.headerHeight + this.props.scrollTop - this._scene.current.offsetTop;
     // if(this.props.isLogDebug) console.log('-- Card.tsx -- renderFabs() _top (click):',this._top,'header height:',this.props.headerHeight,'scrolltop',this.props.scrollTop,'offsetTop (card):',this._scene.current.offsetTop,'=',top);
    
    return (
      <CardFabs locale={this.props.locale} 
        isLogDebug={this.props.isLogDebug}
        program={this.props.program}
        left={this._left}
        top={top}
        isCountdownDisabled={this.props.isCountdownDisabled}
        onTogglePined={this.props.onTogglePined}
        onShowDetails={this.props.onShowDetails}
        onCreateCountdown={this.props.onCreateCountdown}
        onUnHighlight={this.props.onUnHighlight}
        onShare={this.props.onShare} />
    );
  }

  render() {
    if(!this.props.program)
      return null;

    // if(this.props.isLogDebug) console.log('Card render : ',this.props.program,this.props.isHidden);

    if(this.props.isHidden)
      return null; 

    return (
      <div className={'scene ' + (this.props.isHidden ? 'hidden' : 'show')} ref={this._scene}>
        <div className={'card ' + (this.state.isFlipped ? ' is-flipped' : '') + (!this.props.highlightedProgramId || this.props.highlightedProgramId === this.props.program.id ? '' : ' greyed')} 
          onTouchStart={this.props.highlightedProgramId===undefined ? (e) => this.handleTouchStart(e) : () => {}} 
          onTouchMove={this.props.highlightedProgramId===undefined ? (e) => this.handleTouchMove(e,!this.state.isFlipped) : () => {}} 
          onTouchEnd={this.props.highlightedProgramId===undefined ? (e) => this.handleTouchEnd(e) : () => {}}
          onMouseDown={this.props.highlightedProgramId===undefined ? (e) => this.handleMouseDown(e) : () => {}} 
          onMouseMove={this.props.highlightedProgramId===undefined ? (e) => this.handleMouseMove(e,!this.state.isFlipped) : () => {}} 
          onMouseUp={this.props.highlightedProgramId===undefined ? (e) => this.handleMouseUp(e) : () => {}}
          onMouseLeave={this.props.highlightedProgramId===undefined ? (e) => this.handleMouseLeave(e) : () => {}}>
          <div className="card-face card-face--front">
            <IonCard>
              <IonCardContent>
                <IonCardTitle color="dark">{this.props.program.name}</IonCardTitle>
                <IonLabel className="ion-text-center">{this.props.program.weight} - {this.props.program.mode}</IonLabel>
                { this.props.isPined ? <div className="bookmark"><IonIcon icon={bookmark} slot="start" size="large" color="grey"/></div> : null }
              </IonCardContent>
            </IonCard>
          </div>
          <div className="card-face card-face--back">
            <IonCard>
              <IonCardContent className="ion-no-padding">
                <IonGrid>
                  <IonRow>
                    { this.props.program.alarmReverse ? (
                    <IonCol className="ion-text-center ion-no-padding">
                      <IonLabel>{labels[this.props.locale]['ALARM']}</IonLabel>
                    </IonCol>
                      ) : null }
                    { this.props.program.timeBeforeBaking ? (
                    <IonCol className="ion-text-center ion-no-padding">
                      <IonLabel>{labels[this.props.locale]['BAKING']}</IonLabel>
                    </IonCol>
                      ) : null }
                    <IonCol className="ion-text-center ion-no-padding">
                      <IonLabel>{labels[this.props.locale]['TOTAL']}</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    { this.props.program.alarmReverse ? (
                    <IonCol className="ion-text-center ion-no-padding">
                      <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={this.props.program.alarmReverse} />
                    </IonCol>
                      ) : null }
                    { this.props.program.timeBeforeBaking ? (
                    <IonCol className="ion-text-center ion-no-padding">
                      <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={this.props.program.timeBeforeBaking} />
                    </IonCol>
                      ) : null }
                    <IonCol className="ion-text-center ion-no-padding">
                      <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={this.props.program.totalTime} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </div>
        </div>

        { this.renderFabs() }
        
        
      </div>
    );
  }
}

export default Card;
