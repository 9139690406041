import React from 'react';
import PropTypes from 'prop-types';
import { IonButton, IonIcon, IonLabel } from '@ionic/react';
import { search } from 'ionicons/icons';
import './SearchBox.scss';
let labels = require('./SearchBox.json');

function SearchBox(props) {
  return (
    <div className={'searchbox '+props.display+(props.disabled?' disabled':'')}>
      { props.display === 'large' ? <IonIcon icon={search} className="background-icon"/> : null }
      <IonButton color="primary" expand="full" fill="clear" size={props.display==='very-small' ? 'small' : 'large'} routerLink={!props.disabled ? '/search' : null}>
        <IonIcon icon={search} slot="start"/>
        <IonLabel>{labels[props.locale]['SEARCH']}</IonLabel>
      </IonButton>
    </div>
  );
}

SearchBox.propTypes = {
  locale: PropTypes.string,
  isLogDebug: PropTypes.bool,
  display: PropTypes.oneOf(['very-small','small','large']),
  disabled: PropTypes.bool
};
SearchBox.defaultProps = {
  locale: 'en',
  isLogDebug: true
};

export default SearchBox;
