import React from 'react';
import PropTypes from 'prop-types';
import { IonGrid,IonRow, IonCol,IonContent, IonCard, IonButton, IonIcon  } from '@ionic/react';
import { close,square } from 'ionicons/icons';
import Time from '../components/Time';
import TimeCircle from '../components/TimeCircle';
import Timeline from '../components/Timeline';
import TimeTable from '../components/TimeTable';
import MainTimes from '../components/MainTimes';
import CountdownStepNStatus from '../components/CountdownStepNStatus';

import './Countdown.scss';

// let labels = require('./Countdown.json');

// TODO : en faire un composant fonctionnel pour pas l'instancier a chaque appel

class Countdown extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    countdown: PropTypes.object,
    hideModal: PropTypes.func,
    onStopCountdown: PropTypes.func,
    onClearCountdown: PropTypes.func,
    onEndCountdown: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  _isMounted = false;
  _interval = undefined;

  constructor(props: any) {
    super(props);

    this.state = {
      secondsSpent: 0,
      secondsRemaining: 0,
      currentStep: undefined
    };

    this.updateState();
  }

  componentDidMount() {
    this._isMounted = true;
    // if(this.props.isLogDebug) console.log('componentDidMount');
  }
  componentDidUpdate(prevProps, prevState) {
    // var self = this;
    // if(this.props.isLogDebug) console.log('componentDidUpdate');
    if(prevProps.countdown !== this.props.countdown){
      clearInterval(this._interval);
      this.updateState();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    // if(this.props.isLogDebug) console.log('componentWillUnmount');
  }

  updateState() {
    var self = this;
    if(this.props.countdown){
      this._interval = setInterval(function(){
        var newTime = (new Date()).getTime();
        if(self._isMounted){
          self.setState((state,props) => {
            var secondsSpent = 0;
            var secondsRemaining = 0;
            if(props.countdown.status === 'started'){
              secondsSpent = (newTime - props.countdown.startAt) / 1000;
              secondsRemaining = (props.countdown.program.totalTime * 60) - secondsSpent;
            }else if(props.countdown.status === 'stopped'){
              secondsSpent = (props.countdown.stopAt - props.countdown.startAt) / 1000;
              secondsRemaining = (props.countdown.program.totalTime * 60) - secondsSpent;
            }else if(props.countdown.status === 'ended'){
              secondsSpent = props.countdown.program.totalTime * 60;
              secondsRemaining = 0;
            }
            if(secondsRemaining <= 0)
              secondsRemaining = 0;
            if(props.isLogDebug) console.log('-- Countdown.tsx -- interval : ',secondsSpent,secondsRemaining);
            if(secondsRemaining <= 0 || props.countdown.status === 'stopped' || props.countdown.status === 'ended')
              clearInterval(self._interval);

            var currentStep = undefined;
            if(props.countdown.program && props.countdown.program.steps){
              if(props.isLogDebug) console.log('-- Countdown.tsx -- interval : current step',state.currentStep);
              var currentStepIndex = state.currentStep!==undefined ? state.currentStep.index : 0;
              if(props.isLogDebug) console.log('-- Countdown.tsx -- interval : check step... current step index',currentStepIndex);
              for(var i = currentStepIndex; i < props.countdown.program.steps.length; i++){
                currentStep = props.countdown.program.steps[i];
                if(secondsSpent < currentStep.endsAt*60)
                  break;
              }
            }

            if(props.countdown.status === 'ended' && props.onEndCountdown)
              props.onEndCountdown();

            return ({
              secondsSpent: secondsSpent,
              secondsRemaining: secondsRemaining,
              currentStep: currentStep
            });
          });
        }
      },1000);
    }
  }

  render() {
    // console.log('Countdown render : countdown',this.props.countdown);
    // console.log('Countdown render : ',this.state);

    return (
      <>
        <IonContent id="countdown" color="transparent">
          <div className="countdown-wrapper">
            <div className="countdown-header ion-text-right" onClick={this.props.hideModal}>
              <IonButton className="modalClose" color="white" fill="clear">
                <IonIcon icon={close} size="large"/>
              </IonButton>
            </div>
            { this.props.countdown ? (
              <IonCard color="white" className="countdown-contents ion-no-margin ion-padding ion-text-center">

                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>
                    <IonCol className="ion-no-padding ion-align-self-center">
                      <h1>{this.props.countdown.program.name}</h1>
                      <p>{this.props.countdown.program.weight} - {this.props.countdown.program.mode}</p>
                    </IonCol>
                    <IonCol className="ion-no-padding ion-align-self-center">
                      <div className="">
                        <CountdownStepNStatus locale={this.props.locale} isLogDebug={this.props.isLogDebug} countdown={this.props.countdown} currentStep={this.state.currentStep}/>
                        { this.props.countdown.status === 'started' || this.props.countdown.status === 'stopped' || this.props.countdown.status === 'ended' ? (
                          <TimeCircle size="normal" state={this.props.countdown.status}>
                            <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} seconds={this.state.secondsRemaining} />
                          </TimeCircle>
                        ) : null }
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                

                <MainTimes locale={this.props.locale} isLogDebug={this.props.isLogDebug} program={this.props.countdown.program} secondsPasted={this.state.secondsSpent}/>

                <div className="ion-padding-vertical">
                { this.props.countdown.status === 'started' ? (
                  <IonButton className="ion-margin-top" expand="block" onClick={this.props.onStopCountdown}><IonIcon icon={square} slot="start"/>Stop</IonButton>
                  ) : (
                  <IonButton className="ion-margin-top" expand="block" onClick={this.props.onClearCountdown}><IonIcon icon={square} slot="start"/>Clear</IonButton>
                  ) }
                </div>

                <Timeline locale={this.props.locale} isLogDebug={this.props.isLogDebug} program={this.props.countdown.program} secondsPasted={this.state.secondsSpent}/>
                <TimeTable locale={this.props.locale} isLogDebug={this.props.isLogDebug} program={this.props.countdown.program} secondsPasted={this.state.secondsSpent}/>
              </IonCard>
              ) : null }
            
          </div>

        </IonContent>
      </>
    );
  }
};

export default Countdown;