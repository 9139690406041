import {AppConfig}             from '../services/AppConfig';
import StorageService          from "../services/Storage";
let datas = require('../json/datas.json');
// datas : {
//   app_version
//   datas_version
//   machines: {
//     machineid : {
//       id: files[filename].id,
//       trademark : files[filename].trademark,
//       name : files[filename].name,
//       programs : {
//         programid : {
//           "id": "1",
//           "name": "Pain blanc",
//           "weight": "500g",
//           "mode": "Pâle",
//           "preheating": 0,
//           "kneading1": 3,
//           "kneading2": 31,
//           "rise1": 26,
//           "kneading3": 0.25,
//           "rise2": 25,
//           "kneading4": 0.25,
//           "rise3": 55,
//           "baking": 37,
//           "alarm": 157
//         }
//       }
//     }
//   }
// }
interface Step {
  index: number,
  type: string,
  id: string,
  value: number,    // in minutes
  beginsAt?: number, // in minutes
  endsAt?: number // in minutes
};
interface Program {
  id: string,          // id unique par machine
  idMachine: string,
  name: string,
  weight?: string,
  mode?: string,
  steps: Array<Step>,
  alarm?: number,      // duree restante quand l'alarme retentit
  key: string,         // cle unique toutes machines confondues : (machineId)___(programId)
  totalTime?: number,  // en minutes
  alarmReverse?: number,  // duree au bout de laquelle l'alarme retentit
  timeBeforeBaking?: number
};

let DatasService = {
  datas : {},        // charge toutes les donnees
  datasVersion : -1,
  trademarks : [],
  trademarksById : {},
  machines : [],
  machinesById : {},
  currentTrademark : undefined,
  currentMachine : undefined,
  currentPrograms : [],
  currentPrograms2 : [],  // format de Program avec tableau de step
  STORAGE_KEY_MACHINE : 'machine',
  _updateCurrentPrograms : () => {
    console.log('-- Datas.tsx -- _updateCurrentPrograms() ...');
    var programsIds = Object.keys(DatasService.currentMachine.programs);
    var programs = [];
    var programs2 = [];
    for(var i = 0; i < programsIds.length; i++){
      var program = DatasService.currentMachine.programs[programsIds[i]];
      // version 1
      programs.push(program);
      // version 2
      var program2 = {
        id: program.id,
        idMachine: DatasService.currentMachine.id,
        name: program.name,
        weight: program.weight,
        mode: program.mode,
        steps: [],
        alarm: program.alarm,
        key: DatasService.currentMachine.id + '___' + program.id,
        totalTime: undefined,
        alarmReverse: undefined,
        timeBeforeBaking: undefined
      };
      if(program.preparation)
        program2.steps.push({
          index: program2.steps.length,
          type: 'preparation',
          id: 'preparation',
          value: program.preparation
        });
      if(program.preheating)
        program2.steps.push({
          index: program2.steps.length,
          type: 'preheating',
          id: 'preheating',
          value: program.preheating
        });
      if(program.kneading)
        program2.steps.push({
          index: program2.steps.length,
          type: 'kneading',
          id: 'kneading',
          value: program.kneading
        });
      if(program.rise)
        program2.steps.push({
          index: program2.steps.length,
          type: 'rise',
          id: 'rise',
          value: program.rise
        });
      if(program.kneading1)
        program2.steps.push({
          index: program2.steps.length,
          type: 'kneading',
          id: 'kneading1',
          value: program.kneading1
        });
      if(program.kneading2)
        program2.steps.push({
          index: program2.steps.length,
          type: 'kneading',
          id: 'kneading2',
          value: program.kneading2
        });
      if(program.rise1)
        program2.steps.push({
          index: program2.steps.length,
          type: 'rise',
          id: 'rise1',
          value: program.rise1
        });
      if(program.kneading3)
        program2.steps.push({
          index: program2.steps.length,
          type: 'kneading',
          id: 'kneading3',
          value: program.kneading3
        });
      if(program.rise2)
        program2.steps.push({
          index: program2.steps.length,
          type: 'rise',
          id: 'rise2',
          value: program.rise2
        });
      if(program.kneading4)
        program2.steps.push({
          index: program2.steps.length,
          type: 'kneading',
          id: 'kneading4',
          value: program.kneading4
        });
      if(program.rise3)
        program2.steps.push({
          index: program2.steps.length,
          type: 'rise',
          id: 'rise3',
          value: program.rise3
        });
      if(program.baking)
        program2.steps.push({
          index: program2.steps.length,
          type: 'baking',
          id: 'baking',
          value: program.baking
        });
      if(program.baking2)
        program2.steps.push({
          index: program2.steps.length,
          type: 'baking',
          id: 'baking2',
          value: program.baking2
        });
      // additional infos
      if(program2.steps && program2.steps.length > 0){
        program2.totalTime = 0;
        for(var s = 0; s < program2.steps.length; s++){
          program2.steps[s].beginsAt = program2.totalTime;
          program2.totalTime += program2.steps[s].value;
          program2.steps[s].endsAt = program2.totalTime;
        }
      }
      if(program2.alarm)
        program2.alarmReverse = program2.totalTime - program2.alarm;
      if(program2.totalTime && program.baking)
        program2.timeBeforeBaking = program2.totalTime - program.baking;
      programs2.push(program2);
    }
    DatasService.currentPrograms = programs;
    DatasService.currentPrograms2 = programs2;
    console.log('-- Datas.tsx -- currentPrograms2 :',DatasService.currentPrograms2);
  },
  init: (cb) => {
    DatasService.datas = datas;
    // versions
    DatasService.datasVersion = datas.datas_version;
    // listes et tableaux des machines et marques...
    // machinesById
    DatasService.machinesById = datas.machines;
    let machineIds = Object.keys(datas.machines);
    if(datas.machines && machineIds.length>0){
      for(var m = 0; m < machineIds.length; m++){
        let machineId = machineIds[m];
        let machine = datas.machines[machineId];
        // machines
        DatasService.machines.push(machine);
        // trademarks
        let trademark = machine.trademark;
        if(DatasService.trademarks.indexOf(trademark) === -1)
          DatasService.trademarks.push(trademark);
        // trademarksById
        let trademarkIds = Object.keys(DatasService.trademarksById);
        let trademarkMachines = [];
        if(trademarkIds.indexOf(trademark) === -1){
          trademarkMachines.push(machine);
        }else{
          trademarkMachines = DatasService.trademarksById[trademark];
          trademarkMachines.push(machine);
        }
        DatasService.trademarksById[trademark] = trademarkMachines;
      }
    }
    if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- init() machinesById',DatasService.machinesById);
    if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- init() trademarksById',DatasService.trademarksById);
    if(DatasService.machines && DatasService.machines.length>1){  // la 1ere machine est celle de test
      DatasService.currentMachine = DatasService.machines[1];
      DatasService.currentTrademark = DatasService.currentMachine.trademark;
      DatasService._updateCurrentPrograms();
    }
    if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- init()',DatasService.currentMachine,DatasService.currentTrademark);
    // TODO : order by alpha num
    if(cb)
      cb();
  },
  fetchMachine: (cb) => {
    if(StorageService.isEnabled){
      StorageService.get(DatasService.STORAGE_KEY_MACHINE)
        .then((res:any) => { 
          if(res && res.value){
            if(DatasService.machinesById[res.value]){
              DatasService.currentMachine = DatasService.machinesById[res.value];
              DatasService.currentTrademark = DatasService.currentMachine.trademark;
              DatasService._updateCurrentPrograms();
              if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- fetchMachine()',DatasService.currentMachine,DatasService.currentTrademark);
              if(cb)
                cb();
            }
          }
        });
    }else{
      var res = window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + DatasService.STORAGE_KEY_MACHINE];
      if(res){
        if(DatasService.machinesById[res]){
          DatasService.currentMachine = DatasService.machinesById[res];
          DatasService.currentTrademark = DatasService.currentMachine.trademark;
          DatasService._updateCurrentPrograms();
          if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- fetchMachine()',DatasService.currentMachine,DatasService.currentTrademark);
          if(cb)
            cb();
        }
      }
    }
  },
  storeTrademark: (trademark, cb) => {
    // machine 0 par defaut
    if(DatasService.trademarksById && DatasService.trademarksById[trademark]){
      DatasService.currentMachine = DatasService.trademarksById[trademark][0];
      DatasService.currentTrademark = DatasService.currentMachine.trademark;
      DatasService._updateCurrentPrograms();
    }
    // storage
    if(StorageService.isEnabled){
      StorageService.set(DatasService.STORAGE_KEY_MACHINE, DatasService.currentMachine.id)
        .then((res) => {
          if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- storeTrademark()',DatasService.currentMachine,DatasService.currentTrademark); 
          if(cb)
            cb();
        });
    }else{
      window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + DatasService.STORAGE_KEY_MACHINE] = DatasService.currentMachine.id;
      if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- storeTrademark()',DatasService.currentMachine,DatasService.currentTrademark); 
      if(cb)
        cb();
    }
  },
  storeMachine: (machineId, cb) => {
    if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- storeMachine()',machineId); 
    DatasService.currentMachine = DatasService.machinesById[machineId];
    if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- storeMachine()',DatasService.currentMachine,DatasService.currentTrademark); 
    DatasService._updateCurrentPrograms();
    // storage
    if(StorageService.isEnabled){
      StorageService.set(DatasService.STORAGE_KEY_MACHINE, DatasService.currentMachine.id)
        .then((res:string) => { 
          if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- storeMachine()',DatasService.currentMachine,DatasService.currentTrademark); 
          if(cb)
            cb();
        });
    }else{
      window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + DatasService.STORAGE_KEY_MACHINE] = DatasService.currentMachine.id;
      if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- storeMachine()',DatasService.currentMachine,DatasService.currentTrademark); 
      if(cb)
        cb();
    }
  },
  getProgramsWeight: () => {
    var weights = [];
    for(var i = 0; i < DatasService.currentPrograms.length; i++){
      var program = DatasService.currentPrograms[i];
      //if(program.weight){
        var alreadyAdded = false;
        for(var j = 0; j < weights.length; j++){
          if(weights[j].label === program.weight){
            alreadyAdded = true;
            break;
          }
        }
        if(!alreadyAdded){
          weights.push({
            label : program.weight,
            selected : false
          });
        }
      //}
    }
    if(AppConfig.isLogDebug) console.log('-- Datas.tsx -- getProgramsWeight()',weights);
    return weights;
  },
  getProgramsMode: () => {
    var modes = [];
    for(var i = 0; i < DatasService.currentPrograms.length; i++){
      var program = DatasService.currentPrograms[i];
      //if(program.mode){
        var alreadyAdded = false;
        for(var j = 0; j < modes.length; j++){
          if(modes[j].label === program.mode){
            alreadyAdded = true;
            break;
          }
        }
        if(!alreadyAdded){
          modes.push({
            label : program.mode,
            selected : false
          });
        }
      //}
    }
    return modes;
  }
};
export default DatasService;