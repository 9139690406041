import React from 'react';
// import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonMenu, IonContent  } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { IonModal, IonPage, IonSplitPane  } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { AdOptions, AdSize, AdPosition } from "capacitor-admob";

import Home     from './pages/Home';
import Params   from './pages/Params';
import About    from './pages/About';
import Search   from './pages/Search';

import Menu     from './components/Menu';

import Details    from './modals/Details';
import Countdown  from './modals/Countdown';

import {AppConfig}           from './services/AppConfig';
import DatasService          from './services/Datas';
import PinsService           from './services/Pins';
import TranslationsService   from './services/Translations';
import AdMobService          from './services/AdMob';
import AppRateService        from './services/AppRate';
import SocialSharingService  from './services/SocialSharing';
import CountdownService      from './services/Countdown';
import MarketService         from './services/Market';
import history               from "./services/History";
import StorageService        from "./services/Storage";
import GTMService        from "./services/GTM";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';


let labels = require('./App.json');

declare global {
    interface Window { dataLayer: any; }
}


const { StatusBar } = Plugins;

// const App: React.FC = () => (
class App extends React.Component<any,any> {

  static propTypes = {};
  // private dataLayer : any;

  constructor(props: any) {
    super(props);

    this.state = {
      locale: 'fr',              // locale selectionnee
      locales: [],               // locales disponibles
      trademarks: [],            // tableau des noms de marque
      trademarksById: {},        // machines par nom de marque
      machines: [],              // tableau des machines pour la marque selectionnee
      machinesById: {},          // objets machines par machine pour la marque selectionnee

      trademark: undefined,      // marque selectionnee
      machine: undefined,        // machine selectionnee
      programs2: [],             // tableau des programmes pour la machine selectionnee - version 2

      weights: [],               // tableau des categories weight pour la machine selectionnee
      modes: [],                 // tableau des categories modes pour la machine selectionnee
      hasFilterWeight: false,    // true si au moins un filtre weight est selected
      hasFilterMode: false,      // true si au moins un filtre mode est selected

      pinedKeys: [],             // tableau des programmes favoris pour la machine selectionnee

      showProgramModal: false,   // true si on affiche le modal details de programme
      programInModal: undefined, // programme affiche en details
      programInModalIsPined: false,

      showCountdownModal: false, // true si on affiche le modal countdown
      countdown: undefined,      // countdown en cours (independant du modal)

      isTimerActive: false,      // true si un timer est lance
      programInTimer: undefined,  // programme charge dans le timer actif

      splitPaneOpen: false
    };

    this.promptShare = this.promptShare.bind(this);
    this.promptShareProgram = this.promptShareProgram.bind(this);
    this.selectLocale = this.selectLocale.bind(this);
    this.selectTrademark = this.selectTrademark.bind(this);
    this.selectMachine = this.selectMachine.bind(this);
    this.toggleWeight = this.toggleWeight.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.hideProgramModal = this.hideProgramModal.bind(this);
    this.openStore = this.openStore.bind(this);

    this.togglePined2 = this.togglePined2.bind(this);
    this.showProgramModal2 = this.showProgramModal2.bind(this);
    this.createCountdown = this.createCountdown.bind(this);
    this.showCountdownModal = this.showCountdownModal.bind(this);
    this.hideCountdownModal = this.hideCountdownModal.bind(this);
    this.stopCountdown = this.stopCountdown.bind(this);
    this.clearCountdown = this.clearCountdown.bind(this);
    this.endCountdown = this.endCountdown.bind(this);

    this.handleSplitPaneOpen = this.handleSplitPaneOpen.bind(this);

    this.renderAbout = this.renderAbout.bind(this);
    this.renderParams = this.renderParams.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderHome = this.renderHome.bind(this);
  }

  componentDidMount() {
    var self = this;
    try{
      console.log('');
      console.log('');
      console.log('');
      TranslationsService.fetchLocale(TranslationsService.defaultLocale,() => {
        let locale = TranslationsService.currentLocale;
        let locales = TranslationsService.currentLocales;
        if(AppConfig.appRateEnabled) this.initAppRate(locale);
        this.setState({
          locale: locale,
          locales: locales
        });
      });
      DatasService.init(() => {
        if(AppConfig.isLogDebug) console.log('-- App.tsx -- Datas initalized.',);
        this.setState({
          trademarks: DatasService.trademarks,
          trademarksById: DatasService.trademarksById,
          machines: DatasService.machines,
          machinesById: DatasService.machinesById,
          trademark: DatasService.currentTrademark,
          machine: DatasService.currentMachine,
          programs2: DatasService.currentPrograms2,
          weights: DatasService.getProgramsWeight(),
          modes: DatasService.getProgramsMode()
        });
        DatasService.fetchMachine(() => {
          this.setState({
            trademark: DatasService.currentTrademark,
            machine: DatasService.currentMachine,
            programs2: DatasService.currentPrograms2,
            weights: DatasService.getProgramsWeight(),
            modes: DatasService.getProgramsMode()
          });
        });
        PinsService.fetchPins(() => {
          this.setState({
            pinedKeys: PinsService.pinsKeys
          });
        });
      });
      CountdownService.fetchCountdown(function(){
        if(CountdownService.countdown)
          self.setState((state) => ({
            isTimerActive: true,
            countdown: CountdownService.countdown,
            showProgramModal: false,
            programInModal: undefined,
            showCountdownModal: true
          }));  
      });
      this.initBackButton();
      GTMService.push({
        'event': 'app_load',
        'category': process.env.REACT_APP_MODE !== 'pwa' ? 'app' : 'pwa',
        'action': 'app_load',
        'label': undefined,
        'value': undefined
      });

      // APP ONLY
      if(StatusBar!==undefined){
        StatusBar.setBackgroundColor({
          color : '#697680'
        });
      }
      if(AdMobService.isEnabled){
        AdMobService.initialize(AppConfig.adMob_appID);
      }
    }catch(err){
      console.error(err);
    }
  }

  
  initAppRate(locale){
    // NB : obsolete avec le plugin capacitor ?
    // AppRateService.init({
    //   title: labels[locale]['APPRATE_TITLE'],
    //   message: labels[locale]['APPRATE_MESSAGE'],
    //   cancelButtonLabel: labels[locale]['APPRATE_CANCEL'],
    //   laterButtonLabel: labels[locale]['APPRATE_LATER'],
    //   rateButtonLabel: labels[locale]['APPRATE_RATE']//,
    //   // yesButtonLabel: 'Oui',
    //   // noButtonLabel: 'Non',
    //   // appRatePromptTitle: '%@ vous satisfait?',
    //   // feedbackPromptTitle: 'Voulez-vous donner votre avis sur %@ ?'
    // });
  }

  runAd(){
    if(!AdMobService.isEnabled)
      return;

    if(AppConfig.isLogDebug) console.log('-- App.tsx -- runAd()');
    AdMobService.prepareInterstitial({
      adId: AdMobService.interstitielID, 
      adSize: AdSize.SMART_BANNER,
      position: AdPosition.BOTTOM_CENTER,
      hasTabBar: false, // make it true if you have TabBar Layout.
      tabBarHeight: 56 // you can assign custom margin in pixel default is 56
    }).then(() => {
      AdMobService.showInterstitial().then(() => {
        if(AppConfig.isLogDebug) console.log('-- App.tsx -- interstitiel shown.');
      });
    });
    
  }

  promptRate(){
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- promptRate()');
    AppRateService.requestReview();
  }

  promptShare(){
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- promptShare()');
    if(!AppConfig.SocialSharingEnabled)
      return;
    let title = labels[this.state.locale]['SOCIALSHARING_SUBJECT'];
    let text = labels[this.state.locale]['SOCIALSHARING_MESSAGE'];
    let url = AppConfig.storeUrl.googlePlay+this.state.locale;
    let dialogTitle = labels[this.state.locale]['SOCIALSHARING_DIALOGTITLE'];
    SocialSharingService.share(title,text,url,dialogTitle);
  }

  promptShareProgram(program2){
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- promptShareProgram()',program2,this.state.locale);
    if(!AppConfig.SocialSharingEnabled)
      return;
    let title = labels[this.state.locale]['SOCIALSHARING_SUBJECT_PROGRAM'];
    let text = labels[this.state.locale]['SOCIALSHARING_MESSAGE_PROGRAM'] + ' ' + this.state.trademark + ' ' + program2.name;
    let url = AppConfig.storeUrl.googlePlay+this.state.locale;
    let dialogTitle = labels[this.state.locale]['SOCIALSHARING_DIALOGTITLE'];
    SocialSharingService.share(title,text,url,dialogTitle);
  }

  openStore(){
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- openStore()');
    MarketService.market.open(AppConfig.appPackage);
  }

  selectLocale(locale) {
    // locale = { label, code, disabled }
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- selectLocale(',locale,')');
    TranslationsService.storeLocale(locale.code,() => {
      let locale = TranslationsService.currentLocale;
      let locales = TranslationsService.currentLocales;
      this.initAppRate(locale);
      this.setState({
        locale: locale,
        locales: locales
      });
    });
  }

  selectTrademark(trademark) {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- selectTrademark()',trademark);
    DatasService.storeTrademark(trademark,() => {
      this.setState({
        trademark: DatasService.currentTrademark,
        machine: DatasService.currentMachine,
        programs2: DatasService.currentPrograms2,
        weights: DatasService.getProgramsWeight(),
        modes: DatasService.getProgramsMode()
      });
    });
  }

  selectMachine(machineId) {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- selectMachine()',machineId);
    DatasService.storeMachine(machineId,() => {
      this.setState({
        trademark: DatasService.currentTrademark,
        machine: DatasService.currentMachine,
        programs2: DatasService.currentPrograms2,
        weights: DatasService.getProgramsWeight(),
        modes: DatasService.getProgramsMode()
      });
    });
  }

  toggleWeight(weight) {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- toggleWeight()',weight);
    this.setState((state) => {
      var hasFilterWeight = false;    // true si au moins un filtre weight est selected
      for(var i = 0; i < state.weights.length; i++){
        if(state.weights[i].label === weight.label)
          state.weights[i].selected = !state.weights[i].selected;
        if(state.weights[i].selected)
          hasFilterWeight = true;
      }
      state.hasFilterWeight = hasFilterWeight;
      for(var p = 0; p < state.programs2.length; p++){
        var program = state.programs2[p];
        var isHidden = false;
        if(state.hasFilterWeight){
          for(var i = 0; i < state.weights.length; i++){
            if(state.weights[i].label === program.weight && !state.weights[i].selected){
              isHidden = true;
              break;
            }
          }
        }
        if(state.hasFilterMode){
          for(var i = 0; i < state.modes.length; i++){
            if(state.modes[i].label === program.mode && !state.modes[i].selected){
              isHidden = true;
              break;
            }
          }
        }
        program.isHidden = isHidden;
      }
      if(AppConfig.isLogDebug) console.log('-- App.tsx -- toggleWeight()',state.weights,'hasFilterWeight=',hasFilterWeight,'programs :',state.programs2);
      return ({
        weights: [...state.weights],
        hasFilterWeight: state.hasFilterWeight,
        programs: [...state.programs2]
      });
    });
  }

  toggleMode(mode) {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- toggleMode()',mode);
    this.setState((state) =>  {
      var hasFilterMode = false;    // true si au moins un filtre mode est selected
      for(var i = 0; i < state.modes.length; i++){
        if(state.modes[i].label === mode.label)
          state.modes[i].selected = !state.modes[i].selected;
        if(state.modes[i].selected)
          hasFilterMode = true;
      }
      state.hasFilterMode = hasFilterMode;
      for(var p = 0; p < state.programs2.length; p++){
        var program = state.programs2[p];
        var isHidden = false;
        if(state.hasFilterWeight){
          for(var i = 0; i < state.weights.length; i++){
            if(state.weights[i].label === program.weight && !state.weights[i].selected){
              isHidden = true;
              break;
            }
          }
        }
        if(state.hasFilterMode){
          for(var i = 0; i < state.modes.length; i++){
            if(state.modes[i].label === program.mode && !state.modes[i].selected){
              isHidden = true;
              break;
            }
          }
        }
        program.isHidden = isHidden;
      }
      if(AppConfig.isLogDebug) console.log('-- App.tsx -- toggleMode()',state.modes,'hasFilterMode=',hasFilterMode,'programs :',state.programs2);
      return ({
        modes: [...state.modes],
        hasFilterMode: state.hasFilterMode,
        programs: [...state.programs2]
      });
    });
  }

  togglePined2(program2) {
    let self = this;
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- togglePined2()',program2);
    PinsService._togglePin2(program2);
    PinsService.storePins(function(){
      self.setState((state) => {
        let programInModalIsPined = false;
        if(self.state.programInModal && self.state.pinedKeys)
          programInModalIsPined = self.state.pinedKeys.indexOf(self.state.programInModal.key)!==-1;
        return ({
          pinedKeys: PinsService.pinsKeys,
          programInModalIsPined: programInModalIsPined
        });
      });
    });
  }

  showProgramModal2(program2) {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- showProgramModal2()',program2);
    this.setState((state) => {
      let programInModalIsPined = false;
      if(program2 && this.state.pinedKeys)
        programInModalIsPined = this.state.pinedKeys.indexOf(program2.key)!==-1;
      return ({
        showProgramModal: true,
        programInModal: program2,
        programInModalIsPined: programInModalIsPined
      });
    });
    // console.log(history);
    history.push(history.location.pathname,'modal');
    GTMService.push({
      'event': 'program_details',
      'category': process.env.REACT_APP_MODE !== 'pwa' ? 'app' : 'pwa',
      'action': 'open-program-modal',
      'label': this.state.trademark + ' : ' + program2.name + ' ' + program2.weight + ' ' + program2.mode,
      'value': program2.key
    });
  }

  hideProgramModal() {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- hideProgramModal()');
    this.setState((state) => ({
      showProgramModal: false,
      programInModal: undefined,
      programInModalIsPined: false
    }));
  }

  createCountdown(program2) {
    var self = this;
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- createCountdown()',program2);
    CountdownService.createCountdown(program2);
    CountdownService.storeCountdown(function(){
      self.setState((state) => ({
        isTimerActive: true,
        countdown: CountdownService.countdown,
        showProgramModal: false,
        programInModal: undefined,
        showCountdownModal: true
      }));
    });
  }

  showCountdownModal() {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- showCountdownModal()');
    this.setState((state) => ({
      showCountdownModal: true,
      showProgramModal: false,
      programInModal: undefined
    }));
    history.push(history.location.pathname,'modal');
    GTMService.push({
      'event': 'countdown_details',
      'category': process.env.REACT_APP_MODE !== 'pwa' ? 'app' : 'pwa',
      'action': 'open-countdown-modal',
      'label': CountdownService.countdown.program.name + ' ' + CountdownService.countdown.program.weight + ' ' + CountdownService.countdown.program.mode,
      'value': CountdownService.countdown.program.key
    });
  }

  hideCountdownModal() {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- hideCountdownModal()');
    if(AdMobService.isEnabled && this.state.countdown && (this.state.countdown.status==='ended' || this.state.countdown.status==='stopped')){
      this.runAd();
      this.setState((state) => ({
        showCountdownModal: false
      }));
    }else{
      this.setState((state) => ({
        showCountdownModal: false
      }));
    }
  }

  stopCountdown() {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- stopCountdown()');
    CountdownService._stop();
    CountdownService.storeCountdown();
    this.setState((state) => ({
      isTimerActive: true,
      countdown: CountdownService.countdown,
      showProgramModal: false,
      programInModal: undefined,
      showCountdownModal: true
    }));
  }

  clearCountdown() {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- clearCountdown()');
    CountdownService._clear();
    CountdownService.storeCountdown();
    this.setState((state) => ({
      isTimerActive: false,
      countdown: CountdownService.countdown,
      showCountdownModal: false
    }));
  }

  endCountdown() {
    if(AppConfig.isLogDebug) console.log('-- App.tsx -- endCountdown()');
    CountdownService.storeCountdown();
    this.setState((state) => ({
      countdown: CountdownService.countdown
    }));
  }

  initBackButton(){
    window.onpopstate = (evt) => {
      if(AppConfig.isLogDebug) console.log('-- App.tsx -- back button click', evt.State);

      // Close modals if open
      if (this.state.showProgramModal) {
        evt.preventDefault();
        this.hideProgramModal();
        return;
      }
      if (this.state.showCountdownModal) {
        evt.preventDefault();
        this.hideCountdownModal();
        return;
      }

      // Navigate back
      // if(this.props.history)
      //   this.props.history.popState();

    };
  }

  handleSplitPaneOpen(e){
    const menuCtrl = document.querySelector('#menu');
    console.log('handleSplitPaneOpen',menuCtrl,e);
    this.setState({splitPaneOpen:e.detail.visible});
  }


  renderAbout() {
    let functionalities = [
      { label : 'AdMob',           isEnabled : AdMobService.isEnabled,         isMock : AdMobService.isMock },
      { label : 'Storage',         isEnabled : StorageService.isEnabled,       isMock : StorageService.isMock },
      { label : 'AppRate',         isEnabled : AppRateService.isEnabled,       isMock : AppRateService.isMock },
      { label : 'SocialSharing',   isEnabled : SocialSharingService.isEnabled, isMock : SocialSharingService.isMock }
    ];
    return (
      <IonPage>
        <About 
          locale={this.state.locale} 
          storeUrl={AppConfig.storeUrl} 
          onRunAd={this.runAd} 
          dataVersion={DatasService.datasVersion}
          appVersion={AppConfig.appVersion}
          appName={AppConfig.appName}
          isDev={AppConfig.isDev}
          isCordova={AppConfig.isCordova}
          isLogDebug={AppConfig.isLogDebug}
          functionalities={functionalities}
          isTimerActive={this.state.isTimerActive}
          countdown={this.state.countdown}
          onShowCountdown={this.showCountdownModal}
          onOpenStore={this.openStore} 
          onEndCountdown={this.endCountdown}/>
      </IonPage>
    );
  }

  renderParams() {
    return (
      <IonPage>
        <Params 
          locale={this.state.locale} 
          isLogDebug={AppConfig.isLogDebug}
          locales={this.state.locales}
          onSelectLocale={this.selectLocale}
          trademark={this.state.trademark}
          machine={this.state.machine}
          trademarks={this.state.trademarks}
          machines={this.state.trademarksById[this.state.trademark]}
          isTimerActive={this.state.isTimerActive}
          countdown={this.state.countdown}
          onSelectTrademark={this.selectTrademark}
          onSelectMachine={this.selectMachine}
          onOpenStore={this.openStore}
          onShowCountdown={this.showCountdownModal}
          onEndCountdown={this.endCountdown}/>
      </IonPage>
    );
  }

  renderSearch() {
    return (
      <IonPage>
        <Search 
          locale={this.state.locale} 
          isLogDebug={AppConfig.isLogDebug}
          machine={this.state.machine}
          trademark={this.state.trademark}
          programs={this.state.programs2}
          weights={this.state.weights}
          modes={this.state.modes}
          hasFilterWeight={this.state.hasFilterWeight}
          hasFilterMode={this.state.hasFilterMode}
          pinedKeys={this.state.pinedKeys}
          isTimerActive={this.state.isTimerActive}
          countdown={this.state.countdown}
          onToggleWeight={this.toggleWeight}
          onToggleMode={this.toggleMode}
          onTogglePined={this.togglePined2}
          onShowDetails={this.showProgramModal2}
          onCreateCountdown={this.createCountdown}
          onShowCountdown={this.showCountdownModal}
          onEndCountdown={this.endCountdown}
          onShare={this.promptShareProgram} />
      </IonPage>
    );
  }

  renderHome() {
    let pinedPrograms = [];
    if(this.state.programs2){
      for(var i = 0; i < this.state.programs2.length; i++){
        if(this.state.pinedKeys.indexOf(this.state.programs2[i].key)!==-1){
          pinedPrograms.push(this.state.programs2[i]);
        }
      }
    }
    return (
      <IonPage>
        <Home 
          locale={this.state.locale} 
          isLogDebug={AppConfig.isLogDebug}
          machine={this.state.machine}
          trademark={this.state.trademark}
          searchRenderFunction={this.renderSearch}
          programs={pinedPrograms}
          pinedKeys={this.state.pinedKeys}
          isTimerActive={this.state.isTimerActive}
          countdown={this.state.countdown}
          onTogglePined={this.togglePined2}
          onShowDetails={this.showProgramModal2}
          onCreateCountdown={this.createCountdown}
          onShare={this.promptShareProgram}
          onShowCountdown={this.showCountdownModal}
          onEndCountdown={this.endCountdown} />
      </IonPage>
    );
  }

  render() {
    console.log('App render : locale=',this.state.locale);
    // console.log('App render : trademarks=',this.state.trademarks);
    // console.log('App render : machines=',this.state.machines);
    console.log('App render : trademark=',this.state.trademark);
    console.log('App render : machine=',this.state.machine);
    console.log('App render : programs2=',this.state.programs2);
    // console.log('App render : machines=',this.state.trademarksById[this.state.trademark]);
    console.log('App render : programInModal=',this.state.programInModal);
    console.log('App render : countdown=',this.state.countdown);

    let programInModalIsPined = false;
    if(this.state.machine && this.state.programInModal)
      programInModalIsPined = this.state.pinedKeys.indexOf(this.state.programInModal.key)!==-1;

    

    return (
      <IonApp className={this.state.showProgramModal || this.state.showCountdownModal ? 'hasModal' : ''}>
        <IonReactRouter>
          <IonSplitPane contentId="menuContent" onIonSplitPaneVisible={this.handleSplitPaneOpen}>
            <IonMenu side="start" menuId="menu" contentId="menuContent" id="menu">
              <IonContent color="white">
                <Menu 
                  hasParams={true} 
                  hasAbout={true} 
                  hasRate={AppRateService.isEnabled} 
                  hasShare={SocialSharingService.isEnabled} 
                  locale={this.state.locale} 
                  onPromptRate={this.promptRate} 
                  onPromptShare={this.promptShare}
                  splitPaneOpen={this.state.splitPaneOpen}/>
              </IonContent>
            </IonMenu>
            <IonRouterOutlet id="menuContent">
              <Route path="/home" exact={true} render={this.renderHome}/>
              <Route path="/params" exact={true} render={this.renderParams}/>
              <Route path="/about" exact={true} render={this.renderAbout} />
              <Route path="/search" exact={true} render={this.renderSearch} />
              <Route path="/" exact={true} render={this.renderHome}/>
            </IonRouterOutlet>
            <IonModal isOpen={this.state.showProgramModal} onDidDismiss={this.hideProgramModal}>
              { this.state.programInModal ? (
              <Details 
                  locale={this.state.locale}
                  isLogDebug={this.state.isLogDebug}
                  program={this.state.programInModal}
                  isPined={this.state.programInModalIsPined}
                  enableCountdownStart={this.state.countdown===undefined}
                  hideModal={this.hideProgramModal}
                  onTogglePined={this.togglePined2}
                  onRunTimer={this.createCountdown}
                  onShare={this.promptShareProgram} />
                  ) : null }
            </IonModal>
            <IonModal isOpen={this.state.showCountdownModal} onDidDismiss={this.hideCountdownModal}>
              { this.state.countdown ? (
              <Countdown 
                locale={this.state.locale}
                isLogDebug={this.state.isLogDebug}
                countdown={this.state.countdown}
                hideModal={this.hideCountdownModal}
                onStopCountdown={this.stopCountdown} 
                onClearCountdown={this.clearCountdown}
                onEndCountdown={this.endCountdown} />
                ) : null }
            </IonModal>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    );
  }
};

export default App;
