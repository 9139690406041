import MocksService          from '../services/Mocks';
import {AppConfig}           from '../services/AppConfig';
import { Plugins }           from "@capacitor/core";

let AppRateService = {

  isEnabled : AppConfig.appRateEnabled,
  isMock: AppConfig.appRate_isMock,

  displayAppName : AppConfig.appName,
  storeAppURL : {
    //ios: '<app_id>',
    android: 'market://details?id='+AppConfig.appPackage//,
    //windows: 'ms-windows-store://review/?ProductId=<store_id>'
  },
  simpleMode : true,

  init : (labels) => {
    if(AppRateService.isMock){

    }else{

    }
  },


  requestReview : () => {
    if(AppRateService.isMock){
      MocksService.appRate.requestReview();
    }else{
      Plugins.CapacitorRateApp.requestReview();
    }
  } 

};
export default AppRateService;