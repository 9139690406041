import MocksService          from '../services/Mocks';
import {AppConfig}           from '../services/AppConfig';

let MarketService = {

  isEnabled : AppConfig.MarketEnabled,
  isMock: true,

  market : MocksService.market,


  init : (labels) => {
    
  }

};
export default MarketService;