import React from 'react';
import PropTypes from 'prop-types';
import { IonIcon,IonGrid,IonRow,IonCol} from '@ionic/react';
import './CountdownBox.scss';
import Time from '../components/Time';
import TimeCircle from '../components/TimeCircle';
import CountdownStepNStatus from '../components/CountdownStepNStatus';
import { alert } from 'ionicons/icons';
// let labels = require('./CountdownBox.json');

class CountdownBox extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    isTimerActive: PropTypes.bool,
    countdown: PropTypes.object,
    onShowCountdown: PropTypes.func,
    onEndCountdown: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  _isMounted = false;
  _interval = undefined;

  constructor(props: any) {
    super(props);

    this.state = {
      secondsSpent: 0,
      secondsRemaining: 0,
      currentStep: undefined
    };

    this.updateState();
  }

  componentDidMount() {
    this._isMounted = true;
    // if(this.props.isLogDebug) console.log('componentDidMount');
  }
  componentDidUpdate(prevProps, prevState) {
    // var self = this;
    // if(this.props.isLogDebug) console.log('componentDidUpdate');
    if(prevProps.countdown !== this.props.countdown){
      clearInterval(this._interval);
      this.updateState();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    // if(this.props.isLogDebug) console.log('componentWillUnmount');
  }


  updateState() {
    var self = this;
    if(this.props.countdown){
      if(this.props.isLogDebug) console.log('-- CountdownBox.tsx -- updateState()');
      this._interval = setInterval(function(){
        var newTime = (new Date()).getTime();
        if(self._isMounted){
          self.setState((state,props) => {
            var secondsSpent = 0;
            var secondsRemaining = 0;
            if(props.isLogDebug) console.log('-- CountdownBox.tsx -- updateState() status',props.countdown.status);
            if(props.countdown.status === 'started'){
              secondsSpent = (newTime - props.countdown.startAt) / 1000;
              secondsRemaining = (props.countdown.program.totalTime * 60) - secondsSpent;
            }else if(props.countdown.status === 'stopped'){
              secondsSpent = (props.countdown.stopAt - props.countdown.startAt) / 1000;
              secondsRemaining = (props.countdown.program.totalTime * 60) - secondsSpent;
            }else if(props.countdown.status === 'ended'){
              secondsSpent = props.countdown.program.totalTime * 60;
              secondsRemaining = 0;
            }
            if(secondsRemaining <= 0)
              secondsRemaining = 0;
            if(props.isLogDebug) console.log('-- CountdownBox.tsx -- interval : secondsSpent=',secondsSpent,'secondsRemaining=',secondsRemaining,props.countdown);
            if(secondsRemaining <= 0 || props.countdown.status === 'stopped' || props.countdown.status === 'ended')
              clearInterval(self._interval);

            var currentStep = undefined;
            if(props.countdown.program && props.countdown.program.steps){
              if(props.isLogDebug) console.log('-- CountdownBox.tsx -- interval : current step',state.currentStep);
              var currentStepIndex = state.currentStep!==undefined ? state.currentStep.index : 0;
              if(props.isLogDebug) console.log('-- CountdownBox.tsx -- interval : check step... current step index',currentStepIndex);
              for(var i = currentStepIndex; i < props.countdown.program.steps.length; i++){
                currentStep = props.countdown.program.steps[i];
                if(props.isLogDebug) console.log('-- CountdownBox.tsx -- interval : check step... spent < step end ?',secondsSpent,currentStep.endsAt);
                if(secondsSpent < currentStep.endsAt*60)
                  break;
              }
              if(props.isLogDebug) console.log('-- CountdownBox.tsx -- interval : check step... current step',currentStep);
            }

            if(props.countdown.status === 'ended' && props.onEndCountdown)
              props.onEndCountdown();

            return ({
              secondsSpent: secondsSpent,
              secondsRemaining: secondsRemaining,
              currentStep: currentStep
            });
          });
        }
      },1000);
    }
  }

  render() {
    // console.log('CountdownBox render : ',this.state);
    if(!this.props.isTimerActive || !this.props.countdown)
      return <div className={'countdown-box ion-padding hidden'}></div>;

    return (
      <div className={'countdown-box displayed'} onClick={this.props.onShowCountdown}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="title">{this.props.countdown.program.name}</div>
              <div className="small">
                <small>{this.props.countdown.program.weight} - {this.props.countdown.program.mode}</small>
              </div>
            </IonCol>
            <IonCol className="ion-text-right ion-no-padding">
              <CountdownStepNStatus locale={this.props.locale} isLogDebug={this.props.isLogDebug} countdown={this.props.countdown} currentStep={this.state.currentStep}/>
              <TimeCircle size="small" state={this.props.countdown.status}>
                <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} seconds={this.state.secondsRemaining} size="small" />
              </TimeCircle>
              { this.props.countdown.status === 'ended' ? (
                <IonIcon icon={alert} color="danger" className="alert-icon"/>
                ) : null }
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }
}

export default CountdownBox;
