import React from 'react';
import PropTypes from 'prop-types';
import AdSense from 'react-adsense';
import { IonGrid, IonRow, IonCol,IonContent, IonHeader, IonTitle, IonToolbar, IonNote, IonItem, IonButtons, IonButton,IonLabel, IonIcon, IonMenuButton } from '@ionic/react';
import { barcode, code, card, globe, bug, home, search } from 'ionicons/icons';

import {AppConfig}           from '../services/AppConfig';
import CountdownBox    from '../components/CountdownBox';

import './About.scss';

let labels = require('./About.json');

// TODO : en faire un composant fonctionnel pour pas l'instancier a chaque appel

class About extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    storeUrl: PropTypes.object,
    onRunAd: PropTypes.func,
    dataVersion: PropTypes.number,
    appVersion: PropTypes.number,
    appName: PropTypes.string,
    isDev: PropTypes.bool,
    isCordova: PropTypes.bool,
    isLogDebug: PropTypes.bool,
    functionalities: PropTypes.array,
    isTimerActive: PropTypes.bool,
    countdown: PropTypes.object,
    onShowCountdown: PropTypes.func,
    onOpenStore: PropTypes.func,
    onEndCountdown: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  private _header = React.createRef<any>();
  private _search = React.createRef<any>();
  private _last = React.createRef<any>();
  private _isMounted = false;
  private _scrolling = false;
  private _lastScroll = undefined;
  private _currentScrollTop = 0;
  private SCROLLTOP_SEUIL = 1;

  constructor(props: any) {
    super(props);

    this.state = {
      scrolledDown: false,
      isScrollTop: true
    };

    this.onScroll = this.onScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
  }

  componentDidMount() {
    document.title = labels[this.props.locale]['PAGE_METATITLE'];
  }

  onScroll(e) {
    this._scrolling = true;
    this._lastScroll = e;
    this._currentScrollTop = e.detail.scrollTop;
    if(this.props.isLogDebug) console.log('-- About.tsx -- onScroll()',this._lastScroll.detail.deltaY);

    this.doCheckScrollingDown();
    this.doCheckScrollingUp();
  }

  onScrollEnd(e) {
    this._scrolling = false;
    if(!this._lastScroll)
      return;

    // scroll up
    if(this._lastScroll.detail.deltaY < 0){
      this.doCheckScrollingDown();
      this.doCheckScrollingUp();
      return;
    }
      
    // scroll down
    if(this._lastScroll.detail.deltaY > 0){
      this.doCheckScrollingDown();
      this.doCheckScrollingUp();
    }
  }

  doCheckScrollingDown() {
    // si on vient de scroller vers le bas, si on a atteint la hauteur du header, on veut cacher la header toolbar, on update state.scrolledDown si besoin
    if(this.props.isLogDebug) console.log('-- About.tsx -- doCheckScrollingDown()...');
    if(this._lastScroll.detail.deltaY > 0 && this._lastScroll.detail.scrollTop > this._header.current.offsetHeight){
      if(!this.state.scrolledDown){
        if(this.props.isLogDebug) console.log('-- About.tsx -- doCheckScrollingDown() scroll down, switch scrolledDown to true',this._lastScroll.detail.deltaY,this._lastScroll.detail.scrollTop,this._header.current.offsetHeight);
        this.setState({ 
          scrolledDown: true
        });
      }
    }else if(this._lastScroll.detail.deltaY < 0){
      if(this.state.scrolledDown){
        if(this.props.isLogDebug) console.log('-- About.tsx -- doCheckScrollingDown() scroll up, switch scrolledDown to false',this._lastScroll.detail.deltaY);
        this.setState({ 
          scrolledDown: false
        });
      }
    }
  }

  doCheckScrollingUp() {
    // si on a pas scrolle, ou si on vient de scroller vers le haut, on veut afficher le header soit gand soit petit
    if(this.props.isLogDebug) console.log('-- About.tsx -- doCheckScrollingUp()...');
    if(this._lastScroll.detail.scrollTop < this.SCROLLTOP_SEUIL && !this.state.isScrollTop){
      if(this.props.isLogDebug) console.log('-- About.tsx -- doCheckScrollingUp() is at the top, last scrollTop:',this._lastScroll.detail.scrollTop,'state.isScrollTop:',this.state.isScrollTop);
      this.setState({ 
        isScrollTop: true 
      });
    }else if(this._lastScroll.detail.scrollTop >= this.SCROLLTOP_SEUIL && this.state.isScrollTop){
      if(this.props.isLogDebug) console.log('-- About.tsx -- doCheckScrollingUp() is under the top, last scrollTop:',this._lastScroll.detail.scrollTop,'state.isScrollTop:',this.state.isScrollTop);
      this.setState({ 
        isScrollTop: false 
      });
    }
  }

  render() {
    // console.log(this.props.locale);
    // console.log(this.props.storeUrl);

    return (
      <>
        <IonHeader className={'dynamicHeader' + (this.state.scrolledDown ? ' hidden' : '') + (!this.state.isScrollTop ? ' reduced' : '')} ref={this._header}>
          <IonToolbar color="grey">
            <IonButtons slot="start">
              <IonMenuButton menu="menu"  color="white"/>
              <IonButton routerLink="/home" color="white"><IonIcon icon={home} /></IonButton>
              <IonButton routerLink="/search" color="white"><IonIcon icon={search} /></IonButton>
            </IonButtons>
            <IonTitle color="white">{labels[this.props.locale]['TITLE']}</IonTitle>
          </IonToolbar>
          { !this.state.scrolledDown ? (
          <>
            <CountdownBox locale={this.props.locale}
              isLogDebug={this.props.isLogDebug}
              isTimerActive={this.props.isTimerActive}
              countdown={this.props.countdown}
              onShowCountdown={this.props.onShowCountdown}
              onEndCountdown={this.props.endCountdown}/>
          </>
            ) : null }
        </IonHeader>
        <IonContent id="about" color="white" ref={this._search} onIonScroll={this.onScroll} onIonScrollEnd={this.onScrollEnd} scrollEvents={true}>
          
          <IonGrid>
            <IonRow>
              <IonCol className="ion-padding">
                <div className="menu-img ion-text-center ion-margin-bottom"></div>
                <div className="appname ion-text-center">{this.props.appName}</div>
                <div className="version ion-text-center">{labels[this.props.locale]['VERSION']} : <span>{this.props.appVersion}</span></div>
              </IonCol>
            </IonRow>



            { (this.props.isDev) 
              ? 
            <IonRow>
              <IonCol>
                <div className="section">
                  <div className="section-header">
                    <IonItem lines={'none'} color="white">
                      <IonIcon icon={bug} slot="start" color="grey"/>
                      <IonLabel color="grey">{labels[this.props.locale]['DEBUG']}</IonLabel>
                    </IonItem>
                  </div>
                  <div className="section-content ion-padding-horizontal">
                    <IonRow className="row-no-padding">
                      <IonCol className="ion-no-padding">
                        {!this.props.isCordova ? <div><IonNote>Web Application</IonNote></div> : null}
                        {this.props.isDev ? <div><IonNote>Development installation</IonNote></div> : null}
                        {this.props.isLogDebug ? <div><IonNote>Logs debug enabled</IonNote></div> : null}
                      </IonCol>
                      <IonCol className="ion-no-padding">
                        { this.props.functionalities.map((functionality, index) => 
                            <div key={index}><IonNote>{functionality.label} : {functionality.isEnabled ? 'enabled'  : 'disabled'}, {functionality.isMock ? 'mock'  : 'plugin'}</IonNote></div>
                          )}
                      </IonCol>
                    </IonRow>
                  </div>
                </div>
              </IonCol>
            </IonRow>
              : null }

            <IonRow>
              <IonCol>
                <div className="section">
                  <div className="section-header">
                    <IonItem lines={'none'} color="white">
                      <IonIcon icon={barcode} slot="start" color="grey"/>
                      <IonLabel color="grey">{labels[this.props.locale]['DATAS']}</IonLabel>
                    </IonItem>
                  </div>
                  <div className="section-content ion-padding-horizontal">
                    <p>{labels[this.props.locale]['DATAS_TEXT']}</p>
                    <p>{labels[this.props.locale]['DATAS_TEXT_2']}</p>
                    <p className="version"><u>{labels[this.props.locale]['DATAS_TEXT_3']}</u> : {this.props.dataVersion}</p>
                    <div className="ion-text-center">
                      { AppConfig.MarketEnabled ? (
                        <IonButton color="grey" onClick={this.props.onOpenStore}>{labels[this.props.locale]['OPEN_STORE_GOOGLEPLAY']}</IonButton>
                      ) : (
                        <IonButton color="grey" href={AppConfig.storeUrl.googlePlay} target="_blank" rel="referrer noopener">{labels[this.props.locale]['OPEN_STORE_GOOGLEPLAY']}</IonButton>
                      ) }
                    </div>
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="section">
                  <div className="section-header">
                    <IonItem lines={'none'} color="white">
                      <IonIcon icon={code} slot="start" color="grey"/>
                      <IonLabel color="grey">{labels[this.props.locale]['DEVELOPMENT']}</IonLabel>
                    </IonItem>
                  </div>
                  <div className="section-content ion-padding-horizontal">
                    <p>{labels[this.props.locale]['DEV']}</p>
                    <div><u>{labels[this.props.locale]['CREDITS']}</u></div>
                    <ul>
                      <li><a href="https://ionicframework.com" title="Ionic" target="_blank" rel="noopener noreferrer">Ionic</a></li>
                      <li>Icons made by <a href="https://www.freepik.com/" title="Freepik" rel="noopener noreferrer">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon" rel="noopener noreferrer">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</a></li>
                    </ul>
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="section">
                  <div className="section-header">
                    <IonItem lines={'none'} color="white">
                      <IonIcon icon={card} slot="start" color="grey"/>
                      <IonLabel color="grey">{labels[this.props.locale]['ADDS']}</IonLabel>
                    </IonItem>
                  </div>
                  <div className="section-content ion-padding-horizontal">
                    <p>{labels[this.props.locale]['ADDS_TEXT']}</p>
                    { AppConfig.adMobEnabled && this.props.onRunAd ? 
                      (
                        <div>
                          <IonButton onClick={this.props.onRunAd} expand="block" color="grey">{labels[this.props.locale]['ADDS_BUTTON']}</IonButton>
                        </div>
                      ) : null }
                    { AppConfig.adSenseEnabled ? (
                      <AdSense.Google client={AppConfig.adSenseId} slot="5642628518" responsive="true" style={{display:'block'}} format="auto"/>
                    ) : null}
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="section">
                  <div className="section-header">
                    <IonItem lines={'none'} color="white">
                      <IonIcon icon={globe} slot="start" color="grey"/>
                      <IonLabel color="grey">{labels[this.props.locale]['TRANSLATIONS']}</IonLabel>
                    </IonItem>
                  </div>
                  <div className="section-content ion-padding-horizontal">
                    <p>{labels[this.props.locale]['TRAD']}</p>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div className="ion-padding"></div>
          <div className="ion-padding"></div>
          
          <div ref={this._last}></div>

        </IonContent>
      </>
    );
  }
};

export default About;