import React from 'react';
import PropTypes from 'prop-types';
import { IonText  } from '@ionic/react';

import './CountdownStepNStatus.scss';

let labels = require('./CountdownStepNStatus.json');

function CountdownStepNStatus(props) {

  if(!props.countdown)
    return null;

  return (
    <IonText className="countdown-status ion-margin-horizontal" color="grey">
      {props.currentStep ? <span className="step">{labels[props.locale][props.currentStep.id]}</span> : null}
      {props.countdown.status === 'started' ? <span className="animated-dots"><span></span></span> : <span className="stopped-dots"><span></span></span>}
      <span className="status">{labels[props.locale][props.countdown.status.toUpperCase()]}</span>
    </IonText>
  );
}

CountdownStepNStatus.propTypes = {
  locale: PropTypes.string,
  isLogDebug: PropTypes.bool,
  countdown: PropTypes.object,
  currentStep: PropTypes.object
};
CountdownStepNStatus.defaultProps = {
  locale: 'en',
  isLogDebug: true
};


export default CountdownStepNStatus;
