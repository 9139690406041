import React from 'react';
import PropTypes from 'prop-types';
import { IonBadge, IonButton, IonGrid, IonRow, IonCol  } from '@ionic/react';
import './Filters.scss';
let labels = require('./Filters.json');

function Filters(props) {
  // console.log('Filters render : weights=',props.weights);

  if(props.isScrollTop)
    return (
      <IonGrid>
        <IonRow>
          { props.weights.map((weight,index) => (
          <IonCol key={weight.label} className="ion-no-padding">
            <IonButton expand="full" color={weight.selected ? 'primary' : 'light'} className={weight.selected ? 'selected' : ''} onClick={() => props.onToggleWeight(weight)}>{weight.label ? weight.label : labels[props.locale]['NOT_SET']}</IonButton>
          </IonCol>
          )) }
        </IonRow>
        <IonRow>
          { props.modes.map((mode,index) => (
          <IonCol key={mode.label} className="ion-no-padding">
            <IonButton expand="full" color={mode.selected ? 'primary' : 'light'} className={mode.selected ? 'selected' : ''} onClick={() => props.onToggleMode(mode)}>{mode.label ? mode.label : labels[props.locale]['NOT_SET']}</IonButton>
          </IonCol>
          )) }
        </IonRow>
      </IonGrid>
    );

  if(props.hasFilterWeight || props.hasFilterMode)
    return (
      <IonGrid>
        <IonRow>
          <IonCol className="filters-reduced">
            { props.weights.map((weight,index) => {
                if(weight.selected)
                  return <IonBadge key={index} color="primary" className="">{weight.label ? weight.label : labels[props.locale]['NOT_SET']}</IonBadge>;
                return null;
            })}
            { props.modes.map((mode,index) => {
                if(mode.selected)
                  return <IonBadge key={index} color="primary" className="">{mode.label ? mode.label : labels[props.locale]['NOT_SET']}</IonBadge>;
                return null;
            })}
          </IonCol>
        </IonRow>
      </IonGrid>
    );

  return null;
};

Filters.propTypes = {
  locale: PropTypes.string,
  isLogDebug: PropTypes.bool,
  weights: PropTypes.array,
  modes: PropTypes.array,
  hasFilterWeight: PropTypes.bool,
  hasFilterMode: PropTypes.bool,
  isScrollTop: PropTypes.bool,
  onToggleWeight: PropTypes.func,
  onToggleMode: PropTypes.func
};
Filters.defaultProps = {
  locale: 'en',
  isLogDebug: true
};

export default Filters;
