import MocksService            from '../services/Mocks';
import {AppConfig}           from '../services/AppConfig';
import { Plugins }           from "@capacitor/core";

const ADMOB_TEST_INTERSTITIEL_IMG = 'ca-app-pub-3940256099942544/1033173712';
const ADMOB_TEST_BANNER = 'ca-app-pub-3940256099942544/6300978111';

let AdMobService = {

  isEnabled : AppConfig.adMobEnabled,
  isMock: AppConfig.adMob_isMock,
  isTest: AppConfig.adMob_isTest,
  displayBanner: AppConfig.adMob_displayBanner,

  id : AppConfig.adMob_appID,
  interstitielID: ADMOB_TEST_INTERSTITIEL_IMG,   // celui de a propos ou celui test (non video)
  bannerID: ADMOB_TEST_BANNER,  

  initialize:  (id) => {
    AdMobService.interstitielID = !AdMobService.isTest ? AppConfig.adMob_interstitielID : ADMOB_TEST_INTERSTITIEL_IMG;  // celui de a propos ou celui test (non video)
    AdMobService.bannerID = !AdMobService.isTest ? AppConfig.adMob_bannerID : ADMOB_TEST_BANNER;  
    if(AdMobService.isMock){
      MocksService.adMob.initialize(id);
    }else{
      Plugins.AdMob.initialize(id);
    }
  },
  showBanner:  (options) => {
    if(AdMobService.isMock){
      return MocksService.adMob.showBanner(options);
    }else{
      return Plugins.AdMob.showBanner(options);
    }
  },
  removeBanner:  () => {
    if(AdMobService.isMock){
      return MocksService.adMob.removeBanner();
    }else{
      return Plugins.AdMob.removeBanner();
    }
  },
  prepareInterstitial:  (options) => {
    if(AdMobService.isMock){
      return MocksService.adMob.prepareInterstitial(options);
    }else{
      return Plugins.AdMob.prepareInterstitial(options);
    }
  },
  showInterstitial:  () => {
    if(AdMobService.isMock){
      return MocksService.adMob.showInterstitial();
    }else{
      return Plugins.AdMob.showInterstitial();
    }
  }

};
export default AdMobService;