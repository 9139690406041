import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'));

// console.log(process.env.REACT_APP_ENV);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// TODO si PWA
if(process.env.REACT_APP_ENV==='development' || process.env.REACT_APP_MODE!=='pwa'){
  serviceWorker.unregister();
}else{
  serviceWorker.register({
    onUpdate: function(registration){
      // window.location.reload();    // ne sert a rien, car il faut fermer l'onglet navigateur
    }
  });
}