import MocksService          from '../services/Mocks';
import {AppConfig}           from '../services/AppConfig';
import { Plugins }           from "@capacitor/core";

let labels = require('../App.json');

let NotificationsService = {

  isEnabled : AppConfig.NotificationsEnabled,
  isMock: AppConfig.Notifications_isMock,

  pending: undefined,

  schedule : (options) => {
    if(NotificationsService.isMock){
      MocksService.notifications.schedule({notifications:options}).then((scheduleResult) => {
        
      });
    }else{
      Plugins.LocalNotifications.schedule({notifications:options}).then((scheduleResult) => {
        if(scheduleResult){
          // NotificationsService.pending = scheduleResult;
          console.log('scheduleResult',scheduleResult);
          Plugins.LocalNotifications.getPending().then((pendingList) => {
            console.log('pendingList',pendingList);
            NotificationsService.pending = pendingList;
          });
        }
      });
    }
  },

  cancel : () => {
    if(NotificationsService.isMock){
      NotificationsService.pending && MocksService.notifications.cancel(NotificationsService.pending);
    }else{
      NotificationsService.pending && Plugins.LocalNotifications.cancel(NotificationsService.pending);
    }
  },

  sendStarted : (json) => {
    if(!NotificationsService.isEnabled || !json)
      return;

    var options = [];
    var options0 = {
      id: 1,
      title: 'Minuteur : ' + (json.countdown ? json.countdown.program.name : ''),
      body: json.locale ? labels[json.locale]['NOTIFICATION_STARTED'] : '',
      sound: null,
      led: 'ffffff',
      vibrate: true,
      attachments: null,
      // Android-only: set a custom statusbar icon 
      smallIcon: "res://public/assets/icon/icon.png"
    };
    options.push(options0);


    // a chque nouvelle step, on update la notif
    // a totalTime on update la notif
    for(var i = 0; i < json.countdown.program.steps.length; i++){
      var beginAtInSeconds = json.countdown.program.steps[i].beginsAt * 60;

      var optionsStep = {
        id: i+2,
        title: 'Minuteur : ' + (json.countdown ? json.countdown.program.name : ''),
        body: json.locale ? labels[json.locale][json.countdown.program.steps[i].id] : '',
        schedule: {
          at: new Date(json.countdown.startAt + (beginAtInSeconds * 1000))
          //in: beginAtInSeconds, unit: 'second'
        },
        sound: null,
        led: 'ffffff',
        vibrate: true,
        attachments: null
      };
      options.push(optionsStep);
    }

    var endAtInSeconds = json.countdown.program.totalTime * 60;
    var optionsEnd = {
      id: i+3,
      title: 'Minuteur : ' + (json.countdown ? json.countdown.program.name : ''),
      body: json.locale ? labels[json.locale]['NOTIFICATION_ENDED'] : '',
      schedule: {
        at: new Date(json.countdown.startAt + (endAtInSeconds * 1000))
        //in: beginAtInSeconds, unit: 'second'
      },
      sound: null,
      led: 'ffffff',
      vibrate: true,
      attachments: null
    };
    options.push(optionsEnd);
    
    console.log('Send local notification',options);
    NotificationsService.schedule(options);
  },

  cancelAll : () => {
    console.log('Cancel local notifications');
    NotificationsService.cancel();
  }

};
export default NotificationsService;