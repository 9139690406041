const storage = {
  get: (jsonKey) => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  },
  set: (json) => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  }
};
// const admobFree = {
//   interstitial: {
//     prepare: () => {
//       return new Promise(function(resolve, reject) {
//         resolve('');
//       });
//     }
//   }
// };
const adMob = {
  initialize: (id) => {
    console.log('initialize adMob');
  },
  showBanner: (options) => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  },
  removeBanner: () => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  },
  prepareInterstitial: (options) => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  },
  showInterstitial: () => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  }
};
const appRate = {
  // preferences: {
  //   displayAppName: 'xxx',
  //   storeAppURL: {
  //     //ios: '<app_id>',
  //     android: 'market://details?id='//,
  //     //windows: 'ms-windows-store://review/?ProductId=<store_id>'
  //   },
  //   simpleMode: true,
  //   customLocale : {}
  // },
  // promptForRating: (v) => {
  //   console.log('prompt rate!',v);
  // },
  requestReview: () => {
    console.log('prompt rate!');
  }
};
const socialSharing = {
  share: (data) => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  }
};
const market = {
  open: (appPackage) => {
    return new Promise(function(resolve, reject) {
      console.log('open market',appPackage);
      resolve('');
    });
  }
};
const notifications = {
  schedule: (options) => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  },
  cancel: (array) => {
    return new Promise(function(resolve, reject) {
      resolve('');
    });
  }
};

let MocksService = {
  storage: storage,
  // admobFree: admobFree,
  adMob: adMob,
  appRate: appRate,
  socialSharing: socialSharing,
  market: market,
  notifications: notifications
};
export default MocksService;