import {AppConfig}             from '../services/AppConfig';
import StorageService          from "../services/Storage";
import GTMService              from "../services/GTM";
import NotificationsService    from "../services/Notifications";
import TranslationsService     from "../services/Translations";



interface Countdown {
  program: any,
  startAt: number,  // time in millis
  status: string
};
let CountdownService = {
  
  countdown: undefined,
  STORAGE_KEY_COUNTDOWN: 'countdown',
  interval: undefined,
  _start: () => {
    if(AppConfig.isLogDebug) console.log('-- Countdown.tsx -- _start()',CountdownService.countdown);
    var time = (new Date()).getTime();
    CountdownService.countdown.startAt = time;
    CountdownService.countdown.status = 'started';
    CountdownService.countdown.stopAt = undefined;

    clearInterval(CountdownService.interval);
    CountdownService.interval = setInterval(function(){
      CountdownService._checkEnded();
    },1000);
    GTMService.push({
      'event': 'countdown_start',
      'category': process.env.REACT_APP_MODE !== 'pwa' ? 'app' : 'pwa',
      'action': 'countdown_start',
      'label': CountdownService.countdown.program.name + ' ' + CountdownService.countdown.program.weight + ' ' + CountdownService.countdown.program.mode,
      'value': CountdownService.countdown.program.key
    });
    NotificationsService.sendStarted({
      locale: TranslationsService.currentLocale,
      countdown: CountdownService.countdown
    });
  },
  _stop: () => {
    if(AppConfig.isLogDebug) console.log('-- Countdown.tsx -- _stop()',CountdownService.countdown);
    var time = (new Date()).getTime();
    CountdownService.countdown.stopAt = time;
    CountdownService.countdown.status = 'stopped';
    clearInterval(CountdownService.interval);
    GTMService.push({
      'event': 'countdown_stop',
      'category': process.env.REACT_APP_MODE !== 'pwa' ? 'app' : 'pwa',
      'action': 'countdown_stop',
      'label': CountdownService.countdown.program.name + ' ' + CountdownService.countdown.program.weight + ' ' + CountdownService.countdown.program.mode,
      'value': CountdownService.countdown.program.key
    });
    NotificationsService.cancelAll();
  },
  _clear: () => {
    if(AppConfig.isLogDebug) console.log('-- Countdown.tsx -- _clear()',CountdownService.countdown);
    CountdownService.countdown = undefined;
    clearInterval(CountdownService.interval);
  },
  _checkEnded: () => {
    if(!CountdownService.countdown)
      return;
    if(CountdownService.countdown.status === 'started'){
      var newTime = (new Date()).getTime();
      var secondsSpent = (newTime - CountdownService.countdown.startAt) / 1000;
      var secondsRemaining = (CountdownService.countdown.program.totalTime * 60) - secondsSpent;
      if(secondsRemaining <= 0){
        CountdownService.countdown.status = 'ended';
        clearInterval(CountdownService.interval);
        CountdownService.storeCountdown();
        GTMService.push({
          'event': 'countdown_ended',
          'category': process.env.REACT_APP_MODE !== 'pwa' ? 'app' : 'pwa',
          'action': 'countdown_ended',
          'label': CountdownService.countdown.program.name + ' ' + CountdownService.countdown.program.weight + ' ' + CountdownService.countdown.program.mode,
          'value': CountdownService.countdown.program.key
        });
      }
    }
  },
  createCountdown: (program) => {
    CountdownService.countdown = {
      program: program,
      startAt: undefined,
      status: undefined
    };
    CountdownService._start();
  },
  restoreCountdown: (countdown) => {
    CountdownService.countdown = countdown;
    clearInterval(CountdownService.interval);
    CountdownService.interval = setInterval(function(){
      CountdownService._checkEnded();
    },1000);
    CountdownService._checkEnded();
  },
  fetchCountdown: (cb) => {
    // storage
    if(StorageService.isEnabled){
      StorageService.get(CountdownService.STORAGE_KEY_COUNTDOWN)
        .then((res:any) => { 
          if(res && res.value){
            if(res.value!=='undefined'){
              CountdownService.restoreCountdown(JSON.parse(res));
              if(cb)
                cb();
            }else{
              CountdownService._clear();
              if(cb)
                cb();
            }
          }
        });
    }else{
      var res = window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + CountdownService.STORAGE_KEY_COUNTDOWN];
      if(res){
        CountdownService.restoreCountdown(JSON.parse(res));
        if(cb)
          cb();
      }
    }
  },
  storeCountdown: (cb?:any) => {
    // storage
    if(StorageService.isEnabled){
      StorageService.set(CountdownService.STORAGE_KEY_COUNTDOWN, JSON.stringify(CountdownService.countdown))
        .then((res:string) => { 
          if(cb)
            cb();
        });
    }else{
      window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + CountdownService.STORAGE_KEY_COUNTDOWN] = JSON.stringify(CountdownService.countdown);
      if(cb)
        cb();
    }
  }
  
};
export default CountdownService;