import React from 'react';
import PropTypes from 'prop-types';
import { IonContent, IonCard, IonNote, IonButton, IonIcon, IonGrid, IonRow, IonCol  } from '@ionic/react';
import AdSense from 'react-adsense';
import { close,bookmark,arrowDroprightCircle, share } from 'ionicons/icons';

import Timeline       from '../components/Timeline';
import TimeTable      from '../components/TimeTable';
import MainTimes      from '../components/MainTimes';
import AdMobBanner    from '../components/AdMobBanner';
import AdMobService   from '../services/AdMob';
import {AppConfig}    from '../services/AppConfig';
import SocialSharingService  from '../services/SocialSharing';

import './Details.scss';

let labels = require('./Details.json');

// TODO : en faire un composant fonctionnel pour pas l'instancier a chaque appel

class Details extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    program: PropTypes.object,
    isPined: PropTypes.bool,
    enableCountdownStart: PropTypes.bool,
    hideModal: PropTypes.func,
    onTogglePined: PropTypes.func,
    onRunTimer: PropTypes.func,
    onShare: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  constructor(props: any) {
    super(props);
    this.runTimer = this.runTimer.bind(this);
  }

  runTimer(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onRunTimer(this.props.program);
  }

  render() {
    // console.log(this.props.locale);
    // console.log(this.props.storeUrl);
    console.log('Details render : program',this.props.program);

    return (
      <>
        <IonContent id="details" color="transparent">
          <div className="details-wrapper">
            <div className="details-header ion-text-right" onClick={this.props.hideModal}>
              <IonButton className="modalClose" color="white" fill="clear">
                <IonIcon icon={close} size="large"/>
              </IonButton>
            </div>
            { this.props.program ? (
              <IonCard color="white" className="details-contents ion-no-margin ion-padding ion-text-center">
                <h1>{this.props.program.name}</h1>
                <p>{this.props.program.weight} - {this.props.program.mode}</p>
                { this.props.isPined ? <div className="bookmark"><IonIcon icon={bookmark} slot="start" size="large" color="grey"/></div> : null }

                
                <MainTimes locale={this.props.locale} isLogDebug={this.props.isLogDebug} program={this.props.program}/>

                <div className="ion-padding-vertical">
                  { this.props.enableCountdownStart ? (
                    <IonButton className="ion-margin-vertical" expand="block" onClick={(e) => this.runTimer(e)}><IonIcon icon={arrowDroprightCircle} slot="start"/>{labels[this.props.locale]['RUN']}</IonButton>
                  ) : (
                    <>
                    <IonButton className="ion-margin-top" expand="block" disabled={true}><IonIcon icon={arrowDroprightCircle} slot="start"/>{labels[this.props.locale]['RUN']}</IonButton>
                    <IonNote className="cannot-run-message">{labels[this.props.locale]['CANNOT_RUN']}</IonNote>
                    </>
                  ) }
                  <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                      <IonCol className="ion-no-padding ion-no-margin">
                        <IonButton expand="block" fill={this.props.isPined ? undefined : 'outline'} onClick={() => this.props.onTogglePined(this.props.program)}><IonIcon icon={bookmark} slot="start"></IonIcon>{labels[this.props.locale]['FAVORITES']}</IonButton>
                      </IonCol>
                      { SocialSharingService.isEnabled && this.props.onShare ? (
                      <IonCol className="ion-no-padding ion-no-margin">
                        <IonButton expand="block" fill="outline" onClick={() => this.props.onShare(this.props.program)}><IonIcon icon={share} slot="start"></IonIcon>{labels[this.props.locale]['SHARE']}</IonButton>
                      </IonCol>
                      ) : null }
                    </IonRow>
                  </IonGrid>
                </div>
                

                { AppConfig.adSenseEnabled ? (
                  <AdSense.Google client={AppConfig.adSenseId} slot="6186372230" responsive="true" style={{display:'block'}} format="auto"/>
                ) : null}
                { AdMobService.isEnabled && AdMobService.displayBanner ? (
                  <AdMobBanner locale={this.props.locale} isLogDebug={this.props.isLogDebug} id={AdMobService.bannerID}/>
                ) : null}

                <Timeline locale={this.props.locale} isLogDebug={this.props.isLogDebug} program={this.props.program}/>
                <TimeTable locale={this.props.locale} isLogDebug={this.props.isLogDebug} program={this.props.program}/>
          
              </IonCard>
              ) : null }

          </div>

        </IonContent>
      </>
    );
  }
};

export default Details;