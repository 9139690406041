import React from 'react';
import PropTypes from 'prop-types';
import Card          from '../components/Card';

function PinsList(props) {
  if(!props.pinedPrograms || props.pinedPrograms.length === 0)
    return null;

  return (
    <div>
      { props.pinedPrograms.map((program,index) => (
          <Card key={index} 
            locale={props.locale} 
            isLogDebug={props.isLogDebug}
            program={program} 
            isHidden={false}
            isPined={true}
            isCountdownDisabled={props.countdown!==undefined}
            highlightedProgramId={props.highlightedProgramId}
            headerHeight={props.headerHeight}
            scrollTop={props.scrollTop}
            onTogglePined={() => props.onTogglePined(program)}
            onShowDetails={props.onShowDetails}
            onCreateCountdown={props.onCreateCountdown}
            onHighlight={props.onHighlight}
            onUnHighlight={props.onUnHighlight}
            onShare={props.onShare}/>
        )) }
    </div>
  );
}

PinsList.propTypes = {
  locale: PropTypes.string,
  isLogDebug: PropTypes.bool,
  pinedPrograms: PropTypes.array,
  highlightedProgramId: PropTypes.string,
  headerHeight: PropTypes.number,
  scrollTop: PropTypes.number,
  countdown: PropTypes.object,
  onTogglePined: PropTypes.func,
  onShowDetails: PropTypes.func,
  onCreateCountdown: PropTypes.func,
  onHighlight: PropTypes.func,
  onUnHighlight: PropTypes.func,
  onShare: PropTypes.func
};
PinsList.defaultProps = {
  locale: 'en',
  isLogDebug: true
};

export default PinsList;
