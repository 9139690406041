import React from 'react';
import PropTypes from 'prop-types';
import './TimeCircle.scss';
// let labels = require('./TimeCircle.json');

// si on passe en fonctionnel, le children ne marche plus....
class TimeCircle extends React.Component<any,any> {

  static propTypes = {
    size: PropTypes.string,
    state: PropTypes.string
  };

  constructor(props: any) {
    super(props);

  }

  render() {
    return (
      <div className={'time-circle '+this.props.size+' '+this.props.state}>
        {this.props.children}
      </div>
    );
  }
}

export default TimeCircle;
