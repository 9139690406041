import React from 'react';
import PropTypes from 'prop-types';
import { IonCard, IonCardContent, IonProgressBar  } from '@ionic/react';
import './Timeline.scss';
// let labels = require('./Timeline.json');

function Timeline(props) {
  let progressValue = props.secondsPasted!==undefined ? (props.secondsPasted / (props.program.totalTime * 60)) : 0;    // pour 1
  let ended = props.secondsPasted!==undefined && (props.secondsPasted === props.program.totalTime * 60);
  let isRunning = props.secondsPasted!==undefined;

  return (
    <div className="timeline ion-padding-vertical">

      <div className="ticks">
        {props.program.steps.map((step,index) => {
          var left = Math.round(step.beginsAt * 100 / props.program.totalTime);
          var center = Math.round((step.beginsAt + (step.value / 2)) * 100 / props.program.totalTime);

          let isPasted = props.secondsPasted!==undefined && props.secondsPasted >= (step.endsAt * 60);
          let isCurrent = props.secondsPasted!==undefined && props.secondsPasted >= (step.beginsAt * 60) && props.secondsPasted < (step.endsAt * 60);
          let display = null;
          if(isRunning)
            display = isPasted ? ' normal' : (isCurrent ? ' highlight' : ' lowlight');

          return (
            <div key={index} className={display}>
              <div className="tick" style={{left:left+'%'}}></div>
              <div className="bullet" style={{left:center+'%'}}>{step.index}</div>
            </div>
          );
        })}
        <div className={'tick tick-end' + (isRunning ? (ended ? ' normal' : ' lowlight') : '')} style={{left:'100%'}}></div>
      </div>

      { props.secondsPasted!==undefined ? (
      <div className="bar">
        <IonProgressBar value={progressValue} color="primary"></IonProgressBar>
      </div>
      ) : (
      <div className="bar">
        <IonProgressBar value={1} color="medium"></IonProgressBar>
      </div>
      ) }
      
    </div>
  );
}

Timeline.propTypes = {
  locale: PropTypes.string,
  isLogDebug: PropTypes.bool,
  program: PropTypes.object,
  secondsPasted: PropTypes.number
};
Timeline.defaultProps = {
  locale: 'en',
  isLogDebug: true
};

export default Timeline;
