import React from 'react';
import PropTypes from 'prop-types';
// import { Redirect, Route } from 'react-router-dom';
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonMenuButton  } from '@ionic/react';
import { search } from 'ionicons/icons';

import SearchBox     from '../components/SearchBox';
import PinsList      from '../components/PinsList';
import CountdownBox  from '../components/CountdownBox';
import AdMobBanner   from '../components/AdMobBanner';
import AdMobService  from '../services/AdMob';

import './Home.scss';
let labels = require('./Home.json');

class Home extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    machine: PropTypes.object,
    trademark: PropTypes.string,
    searchRenderFunction: PropTypes.func,
    programs: PropTypes.array,
    pinedKeys: PropTypes.array,
    isTimerActive: PropTypes.bool,
    countdown: PropTypes.object,
    onTogglePined: PropTypes.func,
    onShowDetails: PropTypes.func,
    onCreateCountdown: PropTypes.func,
    onShare: PropTypes.func,
    onShowCountdown: PropTypes.func,
    onEndCountdown: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  private _header = React.createRef<any>();
  private _home = React.createRef<any>();
  private _last = React.createRef<any>();
  private _isMounted = false;
  private _scrolling = false;
  private _lastScroll = undefined;
  private _currentScrollTop = 0;
  private SCROLLTOP_SEUIL = 1;

  private LOAD_ON_SCROLL = true;

  constructor(props: any) {
    super(props);

    this.state = {
      programs: [],
      hasMore: true,
      isLoading: false,
      scrolledDown: false,
      isScrollTop: true,
      highlightedProgramId: undefined
    };

    this.onScroll = this.onScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
    this.load = this.load.bind(this);
    this.highlightCard = this.highlightCard.bind(this);
    this.unHighlightCard = this.unHighlightCard.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.title = labels[this.props.locale]['PAGE_METATITLE'];
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- componentDidMount()',this.state.highlightedProgramId);
    this.load();
  }

  componentWillUnmount () {
    this._isMounted = false;
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- componentWillUnmount()',this.state.highlightedProgramId);
  }

  componentDidUpdate(prevProps, prevsState) {
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- did update...');
    if(prevProps.programs !== this.props.programs){
      // if(this.props.isLogDebug) console.log('-- Home.tsx -- did update programs, reset and reload...');
      this.setState({ 
        programs: [],
        hasMore: true,
        isLoading: false
      }, this.load);
    }
  }

  load() {
    if(this.state.isLoading || !this.state.hasMore) 
      return;

    if(!this._isMounted)
      return; 

    this.setState({ 
      isLoading: true
    }, () => {
      this.setState((state,props) => {
        let hasMore = true;
        let currentCount = state.programs.length;
        let maxCount = props.programs.length;
        if(props.isLogDebug) console.log('-- Home.tsx -- load()... currentCount',currentCount, 'maxCount',maxCount);
        var i = state.programs.length;
        var iVisible = 0;
        while(i <= maxCount && iVisible < 5){
          if(!props.programs[i]){
            hasMore = false;
            break;
          }
          state.programs.push(props.programs[i]);
          if(!props.programs[i].isHidden)
            iVisible++;
          if(i === props.programs.length - 1)
            hasMore = false;
          // if(props.isLogDebug) console.log('-- Home.tsx -- load() loading... i=',i,' iVisible=',iVisible);
          i++;
        }
        // if(props.isLogDebug) console.log('-- Home.tsx -- load() loaded. iVisible=',iVisible,'i=',i,state.programs);
        return ({
          hasMore: hasMore,
          programs: [...state.programs],
          isLoading: false 
        });
      });
    });
  }

  onScroll(e) {
    this._scrolling = true;
    this._lastScroll = e;
    this._currentScrollTop = e.detail.scrollTop;

    this.doCheckScrollingDown();
    this.doCheckScrollingUp();
    if(this._lastScroll.detail.deltaY > 0){
      this.doCheckLoad();
    }
  }

  onScrollEnd(e) {
    this._scrolling = false;
    if(!this._lastScroll)
      return;

    // scroll up
    if(this._lastScroll.detail.deltaY < 0){
      this.doCheckScrollingDown();
      this.doCheckScrollingUp();
      return;
    }
      
    // scroll down
    if(this._lastScroll.detail.deltaY > 0){
      this.doCheckScrollingDown();
      this.doCheckScrollingUp();
      this.doCheckLoad();
    }
  }

  doCheckLoad() {
    if(!this.LOAD_ON_SCROLL)
      return;
    // si on arrive en bas, on load la suite
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- doCheckLoad() ',this._last.current.getBoundingClientRect().bottom,'<= (',this._lastScroll.detail.currentY,'+',window.innerHeight,')');
    if((this._last.current.getBoundingClientRect().bottom) <= (window.innerHeight + 60))
      this.load();
  }

  doCheckScrollingDown() {
    // si on vient de scroller vers le bas, si on a atteint la hauteur du header, on veut cacher la header toolbar, on update state.scrolledDown si besoin
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- doCheckScrollingDown()...');
    if(this._lastScroll.detail.deltaY > 0 && this._lastScroll.detail.scrollTop > this._header.current.offsetHeight){
      if(!this.state.scrolledDown){
        // if(this.props.isLogDebug) console.log('-- Home.tsx -- doCheckScrollingDown() scroll down, switch scrolledDown to true',this._lastScroll.detail.deltaY,this._lastScroll.detail.scrollTop,this._header.current.offsetHeight);
        this.setState({ 
          scrolledDown: true
        });
      }
    }else if(this._lastScroll.detail.deltaY < 0){
      if(this.state.scrolledDown){
        // if(this.props.isLogDebug) console.log('-- Home.tsx -- doCheckScrollingDown() scroll up, switch scrolledDown to false',this._lastScroll.detail.deltaY);
        this.setState({ 
          scrolledDown: false
        });
      }
    }
  }

  doCheckScrollingUp() {
    // si on a pas scrolle, ou si on vient de scroller vers le haut, on veut afficher le header soit gand soit petit
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- doCheckScrollingUp()...');
    if(this._lastScroll.detail.scrollTop < this.SCROLLTOP_SEUIL && !this.state.isScrollTop){
      // if(this.props.isLogDebug) console.log('-- Home.tsx -- doCheckScrollingUp() is at the top, last scrollTop:',this._lastScroll.detail.scrollTop,'state.isScrollTop:',this.state.isScrollTop);
      this.setState({ 
        isScrollTop: true 
      });
    }else if(this._lastScroll.detail.scrollTop >= this.SCROLLTOP_SEUIL && this.state.isScrollTop){
      // if(this.props.isLogDebug) console.log('-- Home.tsx -- doCheckScrollingUp() is under the top, last scrollTop:',this._lastScroll.detail.scrollTop,'state.isScrollTop:',this.state.isScrollTop);
      this.setState({ 
        isScrollTop: false 
      });
    }
  }

  highlightCard(program2) {
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- highlightCard()',program2);
    window.navigator.vibrate([10]);
    this.setState((state) => ({
      highlightedProgramId: program2.id
    }));
  }
  unHighlightCard(cb) {
    // if(this.props.isLogDebug) console.log('-- Home.tsx -- unHighlightCard()',cb);
    this.setState((state) => ({
      highlightedProgramId: undefined
    }),() => {
      if(cb)
        cb();
    });
  }

  render() {
    // if(this.props.isLogDebug) console.log('Home render : ',this.props.programs);

    let headerHeight = this.state.scrolledDown ? (this._header.current ? this._header.current.offsetHeight - 56 : 0) : (this._header.current ? this._header.current.offsetHeight : 0);


    return (
      <>
        <IonHeader className={'dynamicHeader' + (this.state.scrolledDown ? ' hidden' : '') + (!this.state.isScrollTop ? ' reduced' : '')} ref={this._header}>
          <IonToolbar color="grey">
            <IonButtons slot="start">
              <IonMenuButton menu="menu" color="white"/>
              <IonButton routerLink="/search" color="white"><IonIcon icon={search} /></IonButton>
            </IonButtons>
            <IonButton slot="end" fill="clear" color="white" routerLink="/params" className="button-with-small">{this.props.machine.name}<small className="verysmall">&nbsp; {this.props.trademark}</small></IonButton>
          </IonToolbar>
          { !this.state.scrolledDown ? (
          <>
            <CountdownBox locale={this.props.locale}
              isLogDebug={this.props.isLogDebug}
              isTimerActive={this.props.isTimerActive}
              countdown={this.props.countdown}
              onShowCountdown={this.props.onShowCountdown}
              onEndCountdown={this.props.endCountdown}/>
            { this.props.pinedKeys.length>0 ? (
            <SearchBox locale={this.props.locale} 
              isLogDebug={this.props.isLogDebug} 
              display={this.state.isScrollTop ? 'small' : 'very-small'} 
              disabled={this.state.highlightedProgramId!==undefined}/>
              ) : null }
          </>
            ) : null }
        </IonHeader>
        <IonContent id="home" color="white" className={'ion-padding-top' + (this.props.pinedKeys.length>0 ? '' : 'flex')} ref={this._home} onIonScroll={this.onScroll} onIonScrollEnd={this.onScrollEnd} scrollEvents={true} scrollY={!this.state.highlightedProgramId}>
          { this.props.pinedKeys.length===0 ? (
            <SearchBox locale={this.props.locale} 
              isLogDebug={this.props.isLogDebug} 
              display={'large'} 
              disabled={this.state.highlightedProgramId!==undefined}/>
              ) : null }
          { this.props.pinedKeys.length>0 ? (
            <PinsList locale={this.props.locale}
              isLogDebug={this.props.isLogDebug}
              pinedPrograms={this.props.programs}
              highlightedProgramId={this.state.highlightedProgramId}
              headerHeight={headerHeight}
              scrollTop={this._currentScrollTop}
              countdown={this.props.countdown}
              onTogglePined={this.props.onTogglePined}
              onShowDetails={this.props.onShowDetails}
              onCreateCountdown={this.props.onCreateCountdown}
              onHighlight={this.highlightCard}
              onUnHighlight={this.unHighlightCard}
              onShare={this.props.onShare} />
            ) : null}
          { !this.state.hasMore ? <div className="no-more-card"></div> : <div className="ion-text-center"><IonButton color="primary" onClick={this.load}>{labels[this.props.locale]['MORE']}</IonButton></div> }
          { AdMobService.isEnabled && AdMobService.displayBanner ? <AdMobBanner locale={this.props.locale} isLogDebug={this.props.isLogDebug} id={AdMobService.bannerID}/> : null}
          <div ref={this._last}></div>
        </IonContent>
      </>
    );
  }
};

export default Home;
