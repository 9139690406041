import { isPlatform } from '@ionic/react';
import MocksService            from '../services/Mocks';

export const AppConfig = {
  appName : 'Minuteur Machine à pain',
  appPackage : 'com.asnidren.app.machineapain',
  appVersion : 0.8,
  isCordova : isPlatform('cordova'),
  isDev : process.env.REACT_APP_ENV !== 'production',
  isLogDebug : process.env.REACT_APP_ENV !== 'production',
  storeUrl: {
    googlePlay: 'https://play.google.com/store/apps/details?id=com.asnidren.app.machineapain&hl='
  },

  testDatasEnabled: process.env.REACT_APP_ENV !== 'production',  // rend accessible la machine TEST - Test

  // GTM ----
  GTMEnabled: process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_MODE === 'pwa',

  // storage ----
  storageEnabled: process.env.REACT_APP_MODE !== 'pwa',
  storage_isMock : false,
  STORAGE_KEY_PREFIX : 'machineapain',

  // app rating ----
  appRateEnabled: process.env.REACT_APP_MODE !== 'pwa',
  appRate_isMock: false,

  // social sharing ----
  SocialSharingEnabled: process.env.REACT_APP_MODE !== 'pwa',
  socialSharing_isMock: false,

  // market ----
  MarketEnabled: process.env.REACT_APP_MODE !== 'pwa' && false,  // plugin non trouve

  // notifications ----
  NotificationsEnabled: true /*process.env.REACT_APP_MODE !== 'pwa'*/,
  Notifications_isMock : false,

  // adSense ----
  adSenseEnabled: process.env.REACT_APP_MODE === 'pwa' && process.env.REACT_APP_ENV === 'production' && false,  // false tant que le site n'est pas valide, sinon plante sur tel
  adSenseId: 'ca-pub-4861368248996834',

  // adMob ----
  adMobEnabled: process.env.REACT_APP_MODE !== 'pwa',
  adMob_isMock: false,
  adMob_isTest: process.env.REACT_APP_ENV !== 'production',
  adMob_displayBanner: false,
  adMob_appID: 'ca-app-pub-4861368248996834~1418043513',
  adMob_interstitielID: 'ca-app-pub-4861368248996834/2156410114',  
  adMob_bannerID: 'ca-app-pub-4861368248996834/7098060857'

  // interstitiel  (A propos) : 'ca-app-pub-4861368248996834/2156410114'
  // interstitiel  (Fin de countdown) : 'ca-app-pub-4861368248996834/2944330873'
  // banner  (modal details) : 'ca-app-pub-4861368248996834/7098060857'

};