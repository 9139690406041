import {AppConfig}             from '../services/AppConfig';
import StorageService          from "../services/Storage";

let PinsService = {

  pinsKeys: [],
  STORAGE_KEY_PINS: 'bookmarks',
  _addPin: (machine,program) => {
    var machineId = machine.id;
    var programId = program.id;
    var key = machineId + '___' + programId;
    if(PinsService.pinsKeys.indexOf(key)===-1) 
      PinsService.pinsKeys.push(key);
  },
  _addPin2: (program2) => {
    if(PinsService.pinsKeys.indexOf(program2.key)===-1) 
      PinsService.pinsKeys.push(program2.key);
  },
  _removePin: (machine,program) => {
    var machineId = machine.id;
    var programId = program.id;
    var key = machineId + '___' + programId;
    if(PinsService.pinsKeys.indexOf(key)!==-1) 
      PinsService.pinsKeys.splice(PinsService.pinsKeys.indexOf(key),1);
  },
  _removePin2: (program2) => {
    if(PinsService.pinsKeys.indexOf(program2.key)!==-1) 
      PinsService.pinsKeys.splice(PinsService.pinsKeys.indexOf(program2.key),1);
  },
  _togglePin: (machine,program) => {
    var machineId = machine.id;
    var programId = program.id;
    var key = machineId + '___' + programId;
    if(PinsService.pinsKeys.indexOf(key)!==-1){
      PinsService.pinsKeys.splice(PinsService.pinsKeys.indexOf(key),1);
    }else{
      PinsService.pinsKeys.push(key);
    }
  },
  _togglePin2: (program2) => {
    if(PinsService.pinsKeys.indexOf(program2.key)!==-1){
      PinsService.pinsKeys.splice(PinsService.pinsKeys.indexOf(program2.key),1);
    }else{
      PinsService.pinsKeys.push(program2.key);
    }
  },
  fetchPins: (cb) => {
    if(StorageService.isEnabled){
      StorageService.get(PinsService.STORAGE_KEY_PINS)
        .then((res:any) => { 
          if(res && res.value){
            PinsService.pinsKeys = JSON.parse(res.value);
            if(cb)
              cb();
          }
        });
    }else{
      var res = window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + PinsService.STORAGE_KEY_PINS];
      if(res){
        PinsService.pinsKeys = JSON.parse(res);
        if(cb)
          cb();
      }
    }
  },
  storePins: (cb) => {
    if(StorageService.isEnabled){
      StorageService.set(PinsService.STORAGE_KEY_PINS, JSON.stringify(PinsService.pinsKeys))
        .then((res:string) => { 
          if(cb)
            cb();
        });
    }else{
      window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + PinsService.STORAGE_KEY_PINS] = JSON.stringify(PinsService.pinsKeys);
      if(cb)
        cb();
    }
  }

};
export default PinsService;