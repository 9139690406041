import React from 'react';
import PropTypes from 'prop-types';
import { IonFabButton, IonIcon  } from '@ionic/react';
import { arrowDroprightCircle, share, bookmark, close } from 'ionicons/icons';
import SocialSharingService  from '../services/SocialSharing';
import './CardFabs.scss';

// let labels = require('./CardFabs.json');

class CardFabs extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    program: PropTypes.object,
    left: PropTypes.number,
    top: PropTypes.number,
    enableCountdownStart: PropTypes.bool,
    onTogglePined: PropTypes.func,
    onShowDetails: PropTypes.func,
    onCreateCountdown: PropTypes.func,
    onShare: PropTypes.func,
    onUnHighlight: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  constructor(props: any) {
    super(props);

    this.handleShare = this.handleShare.bind(this);
    this.handlePin = this.handlePin.bind(this);
    this.handleRun = this.handleRun.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  handleShare(e){
    e.preventDefault();
    this.props.onUnHighlight(() => {
      this.props.onShare(this.props.program);
    });
  }
  handlePin(e){
    e.preventDefault();
    this.props.onUnHighlight(() => {
      this.props.onTogglePined(this.props.program);
    });
  }
  handleRun(e){
    if(this.props.isCountdownDisabled)
      return;
    e.preventDefault();
    this.props.onUnHighlight(() => {
      if(this.props.onCreateCountdown)
        this.props.onCreateCountdown(this.props.program);
    });
  }
  handleClose(e){
    e.preventDefault();
    this.props.onUnHighlight();
  }

  render() {
    return (
      <div className="fabs" style={{'left':this.props.left+'px','top':this.props.top+'px'}}>
        { SocialSharingService.isEnabled && this.props.onShare ? (
        <IonFabButton color="primary" size="small" className="share">
          <IonIcon icon={share} onClick={this.handleShare}></IonIcon>
        </IonFabButton>
        ) : null }
        <IonFabButton color="primary" size="small" className="pin">
          <IonIcon icon={bookmark} onClick={this.handlePin}></IonIcon>
        </IonFabButton>
        <IonFabButton color={this.props.enableCountdownStart ? 'primary' : 'grey'} size="small" className="run" disabled={!this.props.enableCountdownStart}>
          { this.props.enableCountdownStart ? <IonIcon icon={arrowDroprightCircle} onClick={this.handleRun}></IonIcon> : <IonIcon icon={arrowDroprightCircle}></IonIcon> }
        </IonFabButton>
        <IonFabButton color="primary" size="small" className="close">
          <IonIcon icon={close} onClick={this.handleClose}></IonIcon>
        </IonFabButton>
      </div>
    );
  }
}

export default CardFabs;
