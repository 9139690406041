import MocksService          from '../services/Mocks';
import {AppConfig}           from '../services/AppConfig';
import { Plugins }           from "@capacitor/core";

let StorageService = {

  isEnabled : AppConfig.storageEnabled,
  isMock: AppConfig.storage_isMock,
  prefix: AppConfig.STORAGE_KEY_PREFIX,

  get : (key:string) => {
    if(StorageService.isMock){
      return MocksService.storage.get({key: StorageService.prefix+key});
    }else{
      return Plugins.Storage.get({key: StorageService.prefix+key});
    }
  },
  set : (key:string,stringvalue:string) => {
    if(StorageService.isMock){
      return MocksService.storage.set({key:StorageService.prefix+key,value:stringvalue});
    }else{
      return Plugins.Storage.set({key:StorageService.prefix+key,value:stringvalue});
    }
  } 

};
export default StorageService;