import React from 'react';
import PropTypes from 'prop-types';
import { IonList, IonItem, IonMenuToggle, IonIcon, IonLabel, IonNote } from '@ionic/react';
import { build, informationCircleOutline, starOutline, share } from 'ionicons/icons';
import './Menu.scss';
let labels = require('./Menu.json');

function Menu(props) {
  console.log('Menu render : locale=',props.locale);
  console.log('Menu render : labels=',labels[props.locale]);
  return (
    <IonList lines={'none'} className="ion-no-padding">
      { (!props.splitPaneOpen) ? (
        <IonMenuToggle>
          <IonItem className="ion-text-center" routerLink="/home" color="white">
            <IonLabel className="menu-img ion-text-center ion-margin-bottom"></IonLabel>
          </IonItem>
        </IonMenuToggle>
      ) : null }
      { (props.splitPaneOpen) ? (
        <IonItem className="ion-text-center" routerLink="/home" color="white">
          <IonLabel className="menu-img ion-text-center ion-margin-bottom"></IonLabel>
        </IonItem>
      ) : null }

      { (props.hasParams && !props.splitPaneOpen) ? (
        <IonMenuToggle>
          <IonItem className="ion-text-center" routerLink="/params" color="white">
            <IonLabel color="grey">
              <IonIcon icon={build} color="grey" slot="none"/><br/>
              {labels[props.locale]['PARAMS']}
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      ) : null }
      { (props.hasParams && props.splitPaneOpen) ? (
        <IonItem className="ion-text-center" routerLink="/params" color="white">
          <IonLabel color="grey">
            <IonIcon icon={build} color="grey" slot="none"/><br/>
            {labels[props.locale]['PARAMS']}
          </IonLabel>
        </IonItem>
      ) : null }

      { (props.hasAbout && !props.splitPaneOpen) ? (
        <IonMenuToggle>
          <IonItem className="ion-text-center" routerLink="/about" color="white">
            <IonLabel color="grey">
              <IonIcon icon={informationCircleOutline} color="grey" slot="none"/><br/>
              {labels[props.locale]['ABOUT']}
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      ) : null }
      { (props.hasAbout && props.splitPaneOpen) ? (
        <IonItem className="ion-text-center" routerLink="/about" color="white">
          <IonLabel color="grey">
            <IonIcon icon={informationCircleOutline} color="grey" slot="none"/><br/>
            {labels[props.locale]['ABOUT']}
          </IonLabel>
        </IonItem>
      ) : null }

      { (props.hasRate && props.onPromptRate && !props.splitPaneOpen) ? (
        <IonMenuToggle>
          <IonItem className="ion-text-center" onClick={props.onPromptRate} color="white">
            <IonLabel color="grey">
              <IonIcon icon={starOutline} color="grey" slot="none"/>
              <IonIcon icon={starOutline} color="grey" slot="none"/>
              <IonIcon icon={starOutline} color="grey" slot="none"/>
              <IonIcon icon={starOutline} color="grey" slot="none"/>
              <IonIcon icon={starOutline} color="grey" slot="none"/><br/>
              <IonNote color="grey">{labels[props.locale]['RATE_THIS_APP']}</IonNote>
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      ) : null }
      { (props.hasRate && props.onPromptRate && props.splitPaneOpen) ? (
        <IonItem className="ion-text-center" onClick={props.onPromptRate} color="white">
          <IonLabel color="grey">
            <IonIcon icon={starOutline} color="grey" slot="none"/>
            <IonIcon icon={starOutline} color="grey" slot="none"/>
            <IonIcon icon={starOutline} color="grey" slot="none"/>
            <IonIcon icon={starOutline} color="grey" slot="none"/>
            <IonIcon icon={starOutline} color="grey" slot="none"/><br/>
            <IonNote color="grey">{labels[props.locale]['RATE_THIS_APP']}</IonNote>
          </IonLabel>
        </IonItem>
      ) : null }

      { (props.hasShare && !props.splitPaneOpen) ? (
        <IonMenuToggle>
          <IonItem className="ion-text-center" onClick={props.onPromptShare} color="white">
            <IonLabel color="grey">
              <IonIcon icon={share} color="grey" slot="none"/><br/>
              <IonNote color="grey">{labels[props.locale]['SHARE_THIS_APP']}</IonNote>
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      ) : null }
      { (props.hasShare && props.splitPaneOpen) ? (
        <IonItem className="ion-text-center" onClick={props.onPromptShare} color="white">
          <IonLabel color="grey">
            <IonIcon icon={share} color="grey" slot="none"/><br/>
            <IonNote color="grey">{labels[props.locale]['SHARE_THIS_APP']}</IonNote>
          </IonLabel>
        </IonItem>
      ) : null }
    </IonList>
  );
}

Menu.propTypes = {
  hasParams: PropTypes.bool,
  hasAbout: PropTypes.bool,
  hasRate: PropTypes.bool,
  hasShare: PropTypes.bool,
  locale: PropTypes.string,
  onPromptRate: PropTypes.func,
  onPromptShare: PropTypes.func,
  splitPaneOpen: PropTypes.bool
};
Menu.defaultProps = {
  hasParams: false,
  hasAbout: false,
  hasRate: false,
  hasShare: false,
  locale: 'en',
  onPromptRate: undefined,
  onPromptShare: undefined,
  splitPaneOpen: false
};

export default Menu;
