import React from 'react';
import PropTypes from 'prop-types';
import './Time.scss';

// let labels = require('./Time.json');

// <Time locale={this.props.locale} isLogDebug={this.props.isLogDebug} minutes={this.props.minutes} />

function Time(props) {

  if(props.minutes===undefined && props.seconds===undefined)
    return null;

  let f_hours = 0;
  let f_minutes = 0;
  let f_secondes = 0;


  if(props.minutes!==undefined){
    f_hours = (props.minutes > 0 ? Math.floor( props.minutes / 60) : 0);
    f_minutes = (props.minutes > 0 ? Math.floor( props.minutes % 60) : 0);
    f_secondes = Math.ceil(props.minutes - f_minutes - 60*f_hours > 0 ? (props.minutes - f_minutes - 60*f_hours) * 60 : 0);
    // if(props.isLogDebug) console.log('Time render : ',props.minutes,f_hours,f_minutes,f_secondes);
  }

  if(props.seconds!==undefined){
    // if(props.isLogDebug) console.log('Time render : ',props.seconds);
    f_hours = (props.seconds > 0 ? Math.floor( props.seconds / 60 / 60 ) : 0);
    f_minutes = (props.seconds > 0 ? Math.floor( (props.seconds - 60*60*f_hours) / 60 ) : 0);  
    f_secondes = (props.seconds > 0 ? Math.floor( props.seconds - 60*60*f_hours - 60*f_minutes ) : 0);  
  }

  if(f_secondes===0 && f_minutes===0 && f_hours===0)
    return(
      <span className={'time zero '+ props.size}>
        <span className="seconds">0</span>
      </span>
    );

  if(f_secondes>0 && f_minutes===0 && f_hours===0)
    return (
      <span className={'time only-seconds '+ props.size}>
        <span className="seconds">{f_secondes}</span>
      </span>
    );

  if(f_secondes===0 && f_minutes>0 && f_hours===0)
    return (
      <span className={'time only-minutes '+ props.size}>
        <span className="minutes">{f_minutes}</span>
      </span>
    );

  if(f_minutes>0 && f_hours===0)
    return (
      <span className={'time only-minutes-seconds '+ props.size}>
        <span className="minutes">{f_minutes}</span>
        { f_secondes>0 ? <span className="seconds">{(f_secondes>=0 && f_secondes<10 ? '0' : '') + f_secondes}</span> : null }
      </span>
    );

  return (
    <span className={'time '+ props.size}>
      <span className="hours">{f_hours}</span>
      <span className="minutes">{(f_minutes>=0 && f_minutes<10 ? '0' : '') + f_minutes}</span>
      { f_secondes>0 ? <span className="seconds">{(f_secondes>=0 && f_secondes<10 ? '0' : '') + f_secondes}</span> : null }
    </span>
  );
}

Time.propTypes = {
  locale: PropTypes.string,
  isLogDebug: PropTypes.bool,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  size: PropTypes.string
};
Time.defaultProps = {
  locale: 'en',
  isLogDebug: true
};

export default Time;
