import MocksService            from '../services/Mocks';
import {AppConfig}           from '../services/AppConfig';

let GTMService = {

  isEnabled : AppConfig.GTMEnabled,

  push:  (json) => {
    console.log('Push datalayer',json);
    if(window.dataLayer)
      window.dataLayer.push(json);
  },

};
export default GTMService;