import React from 'react';
import PropTypes from 'prop-types';
import { AdOptions, AdSize, AdPosition } from "capacitor-admob";
import AdMobService                      from '../services/AdMob';
// import './AdMobBanner.scss';

// let labels = require('./AdMobBanner.json');

class AdMobBanner extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    id: PropTypes.string
    
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  constructor(props: any) {
    super(props);
  }


  componentDidMount() {
    const options = {
      adId: this.props.id,
      adSize: AdSize.BANNER,
      position: AdPosition.BOTTOM_CENTER
    };
    AdMobService.showBanner(options).then(
      value => {
        console.log(value); // true
      },
      error => {
        console.error(error); // show error
      }
    );
    // Subscibe Banner Event Listener
    // AdMob.addListener("onAdLoaded", (info: boolean) => {
    //   console.log("Banner Ad Loaded");
    // });
  }

  componentWillUnmount() {
    AdMobService.removeBanner().then(
      value => {
        console.log(value); // true
      },
      error => {
        console.error(error); // show error
      }
    );
  }
  

  render() {
    return (
      <div className="adMobBanner">
        
      </div>
    );
  }
}

export default AdMobBanner;
