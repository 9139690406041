import {AppConfig}             from '../services/AppConfig';
import StorageService          from "../services/Storage";

let TranslationsService = {

  defaultLocale: 'fr',
  currentLocale: 'fr',
  currentLocales: [
    { label: 'FR', code: 'fr', disabled: true },
    { label: 'EN', code: 'en', disabled: false }
  ],
  STORAGE_KEY_LANG: 'lang',
  _updateLocales: (newLocaleCode:string) => {
    TranslationsService.currentLocale = newLocaleCode;
    for(var i = 0; i < TranslationsService.currentLocales.length; i++){
      TranslationsService.currentLocales[i].disabled = TranslationsService.currentLocales[i].code === TranslationsService.currentLocale;
    }
  },
  fetchLocale: (defaultLocale,cb) => {
    if(StorageService.isEnabled){
      StorageService.get(TranslationsService.STORAGE_KEY_LANG)
        .then((res:any) => { 
          if(res && res.value){
            // console.log('fetchLocale res=',res.value);
            TranslationsService._updateLocales(res.value);
            // console.log('fetchLocale TranslationsService.currentLocale=',TranslationsService.currentLocale);
            // console.log('fetchLocale TranslationsService.currentLocales=',TranslationsService.currentLocales);
            if(cb)
              cb();
          }
        })
        .catch((err) => {
          console.error('fetchLocale ',err);
          if(defaultLocale){
            TranslationsService._updateLocales(defaultLocale);
            if(cb)
              cb();
          }
        });
    }else{
      var res = window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + TranslationsService.STORAGE_KEY_LANG];
      if(res){
        TranslationsService._updateLocales(res);
        if(cb)
          cb();
      }else if(defaultLocale){
        TranslationsService._updateLocales(defaultLocale);
        if(cb)
          cb();
      }
    }
  },
  storeLocale: (localeCode, cb) => {
    if(StorageService.isEnabled){
      StorageService.set(TranslationsService.STORAGE_KEY_LANG, localeCode)
        .then((res:string) => { 
          TranslationsService._updateLocales(localeCode);
          if(cb)
            cb();
        });
    }else{
      window.localStorage[AppConfig.STORAGE_KEY_PREFIX + '_' + TranslationsService.STORAGE_KEY_LANG] = localeCode;
      TranslationsService._updateLocales(localeCode);
      if(cb)
        cb();
    }
  }

};
export default TranslationsService;