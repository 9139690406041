import React from 'react';
import PropTypes from 'prop-types';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonMenuButton  } from '@ionic/react';
import { home,search } from 'ionicons/icons';

import CountdownBox  from '../components/CountdownBox';
import FormParams    from '../components/FormParams';

// import './Params.scss';

let labels = require('./Params.json');

// TODO : en faire un composant fonctionnel pour pas l'instancier a chaque appel

class Params extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    locales: PropTypes.array,
    trademark: PropTypes.string,
    machine: PropTypes.object,
    onSelectLocale: PropTypes.func,
    trademarks: PropTypes.array,
    machines: PropTypes.array,
    isTimerActive: PropTypes.bool,
    countdown: PropTypes.object,
    onSelectTrademark: PropTypes.func,
    onSelectMachine: PropTypes.func,
    onShowCountdown: PropTypes.func,
    onEndCountdown: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  private _header = React.createRef<any>();
  private _search = React.createRef<any>();
  private _last = React.createRef<any>();
  private _isMounted = false;
  private _scrolling = false;
  private _lastScroll = undefined;
  private _currentScrollTop = 0;
  private SCROLLTOP_SEUIL = 1;

  constructor(props: any) {
    super(props);

    this.state = {
      scrolledDown: false,
      isScrollTop: true
    };

    this.onScroll = this.onScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
  }

  componentDidMount() {
    document.title = labels[this.props.locale]['PAGE_METATITLE'];
  }

  onScroll(e) {
    this._scrolling = true;
    this._lastScroll = e;
    this._currentScrollTop = e.detail.scrollTop;
    if(this.props.isLogDebug) console.log('-- Params.tsx -- onScroll()',this._lastScroll.detail.deltaY);

    this.doCheckScrollingDown();
    this.doCheckScrollingUp();
  }

  onScrollEnd(e) {
    this._scrolling = false;
    if(!this._lastScroll)
      return;

    // scroll up
    if(this._lastScroll.detail.deltaY < 0){
      this.doCheckScrollingDown();
      this.doCheckScrollingUp();
      return;
    }
      
    // scroll down
    if(this._lastScroll.detail.deltaY > 0){
      this.doCheckScrollingDown();
      this.doCheckScrollingUp();
    }
  }

  doCheckScrollingDown() {
    // si on vient de scroller vers le bas, si on a atteint la hauteur du header, on veut cacher la header toolbar, on update state.scrolledDown si besoin
    if(this.props.isLogDebug) console.log('-- Params.tsx -- doCheckScrollingDown()...');
    if(this._lastScroll.detail.deltaY > 0 && this._lastScroll.detail.scrollTop > this._header.current.offsetHeight){
      if(!this.state.scrolledDown){
        if(this.props.isLogDebug) console.log('-- Params.tsx -- doCheckScrollingDown() scroll down, switch scrolledDown to true',this._lastScroll.detail.deltaY,this._lastScroll.detail.scrollTop,this._header.current.offsetHeight);
        this.setState({ 
          scrolledDown: true
        });
      }
    }else if(this._lastScroll.detail.deltaY < 0){
      if(this.state.scrolledDown){
        if(this.props.isLogDebug) console.log('-- Params.tsx -- doCheckScrollingDown() scroll up, switch scrolledDown to false',this._lastScroll.detail.deltaY);
        this.setState({ 
          scrolledDown: false
        });
      }
    }
  }

  doCheckScrollingUp() {
    // si on a pas scrolle, ou si on vient de scroller vers le haut, on veut afficher le header soit gand soit petit
    if(this.props.isLogDebug) console.log('-- Params.tsx -- doCheckScrollingUp()...');
    if(this._lastScroll.detail.scrollTop < this.SCROLLTOP_SEUIL && !this.state.isScrollTop){
      if(this.props.isLogDebug) console.log('-- Params.tsx -- doCheckScrollingUp() is at the top, last scrollTop:',this._lastScroll.detail.scrollTop,'state.isScrollTop:',this.state.isScrollTop);
      this.setState({ 
        isScrollTop: true 
      });
    }else if(this._lastScroll.detail.scrollTop >= this.SCROLLTOP_SEUIL && this.state.isScrollTop){
      if(this.props.isLogDebug) console.log('-- Params.tsx -- doCheckScrollingUp() is under the top, last scrollTop:',this._lastScroll.detail.scrollTop,'state.isScrollTop:',this.state.isScrollTop);
      this.setState({ 
        isScrollTop: false 
      });
    }
  }

  render() {
    return (
      <>
        <IonHeader className={'dynamicHeader' + (this.state.scrolledDown ? ' hidden' : '') + (!this.state.isScrollTop ? ' reduced' : '')} ref={this._header}>
          <IonToolbar color="grey">
            <IonButtons slot="start">
              <IonMenuButton menu="menu" color="white" />
              <IonButton routerLink="/home" color="white"><IonIcon icon={home} /></IonButton>
              <IonButton routerLink="/search" color="white"><IonIcon icon={search} /></IonButton>
            </IonButtons>
            <IonTitle color="white">{labels[this.props.locale]['TITLE']}</IonTitle>
          </IonToolbar>
          { !this.state.scrolledDown ? (
          <>
            <CountdownBox locale={this.props.locale}
              isLogDebug={this.props.isLogDebug}
              isTimerActive={this.props.isTimerActive}
              countdown={this.props.countdown}
              onShowCountdown={this.props.onShowCountdown}
              onEndCountdown={this.props.endCountdown}/>
          </>
            ) : null }
        </IonHeader>
        <IonContent id="params" color="white">
          <FormParams 
            locale={this.props.locale} 
            isLogDebug={this.props.isLogDebug} 
            locales={this.props.locales}
            onSelectLocale={this.props.onSelectLocale}
            trademark={this.props.trademark}
            machine={this.props.machine}
            trademarks={this.props.trademarks}
            machines={this.props.machines}
            onSelectTrademark={this.props.onSelectTrademark}
            onSelectMachine={this.props.onSelectMachine}
            onOpenStore={this.props.onOpenStore}/>

          <div className="ion-padding"></div>
          <div className="ion-padding"></div>
          
          <div ref={this._last}></div>
        </IonContent>
      </>
    );
  }
};

export default Params;
