import React from 'react';
import PropTypes from 'prop-types';
import { IonGrid,IonRow,IonCol, IonCard, IonCardContent, IonLabel  } from '@ionic/react';
import Time from '../components/Time';
import './MainTimes.scss';
let labels = require('./MainTimes.json');

function MainTimes(props) {
  let alarmPasted = props.secondsPasted!==undefined && props.secondsPasted >= (props.program.alarmReverse * 60);
  let bakingPasted = props.secondsPasted!==undefined && props.secondsPasted >= (props.program.timeBeforeBaking * 60);
  let ended = props.secondsPasted!==undefined && props.secondsPasted >= (props.program.totalTime * 60);
  // console.log('MainTimes render : ',props.secondsPasted);

  return (
    <IonCard className="main-times">
      <IonCardContent className="ion-no-padding">
        <IonGrid>
          <IonRow>
            { props.program.alarmReverse ? (
            <IonCol className={'ion-text-center ion-no-padding' + (alarmPasted ? ' lowlight' : '')}>
              <IonLabel>{labels[props.locale]['ALARM']}</IonLabel>
            </IonCol>
              ) : null }
            { props.program.timeBeforeBaking ? (
            <IonCol className={'ion-text-center ion-no-padding' + (bakingPasted ? ' lowlight' : '')}>
              <IonLabel>{labels[props.locale]['BAKING']}</IonLabel>
            </IonCol>
              ) : null }
            <IonCol className={'ion-text-center ion-no-padding' + (ended ? ' lowlight' : '')}>
              <IonLabel>{labels[props.locale]['TOTAL']}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            { props.program.alarmReverse ? (
            <IonCol className={'ion-text-center ion-no-padding' + (alarmPasted ? ' lowlight' : '')}>
              <Time locale={props.locale} isLogDebug={props.isLogDebug} minutes={props.program.alarmReverse} />
            </IonCol>
              ) : null }
            { props.program.timeBeforeBaking ? (
            <IonCol className={'ion-text-center ion-no-padding' + (bakingPasted ? ' lowlight' : '')}>
              <Time locale={props.locale} isLogDebug={props.isLogDebug} minutes={props.program.timeBeforeBaking} />
            </IonCol>
              ) : null }
            <IonCol className={'ion-text-center ion-no-padding' + (ended ? ' lowlight' : '')}>
              <Time locale={props.locale} isLogDebug={props.isLogDebug} minutes={props.program.totalTime} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
}

MainTimes.propTypes = {
  locale: PropTypes.string,
  isLogDebug: PropTypes.bool,
  program: PropTypes.object,
  secondsPasted: PropTypes.number
};
MainTimes.defaultProps = {
  locale: 'en',
  isLogDebug: true
};

export default MainTimes;
