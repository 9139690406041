import { IonList, IonItem, IonButton, IonListHeader, IonLabel, IonNote, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';
import PropTypes from 'prop-types';
import {AppConfig}             from '../services/AppConfig';

import './FormParams.scss';
let labels = require('./FormParams.json');

class FormParams extends React.Component<any,any> {

  static propTypes = {
    locale: PropTypes.string,
    isLogDebug: PropTypes.bool,
    trademark: PropTypes.string,
    machine: PropTypes.object,
    locales: PropTypes.array,
    onSelectLocale: PropTypes.func,
    trademarks: PropTypes.array,
    machines: PropTypes.array,
    onSelectTrademark: PropTypes.func,
    onSelectMachine: PropTypes.func,
    onOpenStore: PropTypes.func
  };

  static defaultProps = {
    locale: 'en',
    isLogDebug: true
  };

  constructor(props: any) {
    super(props);


    this.selectTrademark = this.selectTrademark.bind(this);
    this.selectMachine = this.selectMachine.bind(this);
  }

  selectTrademark(v) {
    // on check que c'est pas undefined car le onIonChange fire 2 fois
    if(this.props.isLogDebug) console.log('-- FormParams.tsx -- selectTrademark()',v);
    if(v)
      this.props.onSelectTrademark(v);
  }
  selectMachine(v) {
    // on check que c'est pas undefined car le onIonChange fire 2 fois
    if(this.props.isLogDebug) console.log('-- FormParams.tsx -- selectMachine()',v);
    if(v)
      this.props.onSelectMachine(v);
  }

  render() {
    console.log('FormParams render : trademarks=',this.props.trademarks);
    console.log('FormParams render : trademark=',this.props.trademark);
    console.log('FormParams render : machine=',this.props.machine);
    console.log('FormParams render : machines=',this.props.machines);
    console.log('FormParams render : appConfig=',AppConfig);

    return (
      <div>
        <IonList lines={'none'} className="ion-no-padding" color="white">
          <IonListHeader color="light">{labels[this.props.locale]['MACHINE']}</IonListHeader>
          <IonItem className="item-select" color="white">
            <IonLabel color="grey">{labels[this.props.locale]['MACHINE_TRADEMARK']}</IonLabel>
            <IonSelect name="trademark" value={this.props.trademark} onIonChange={(e) => this.selectTrademark((e.target as HTMLSelectElement).value)} slot="end" color="primary">
              { this.props.trademarks.map((trademark, index) => {
                if(AppConfig.testDatasEnabled || trademark !== 'TEST')
                  return <IonSelectOption key={index} value={trademark}>{trademark}</IonSelectOption>;
                return null;
              })}
            </IonSelect>
          </IonItem>
          <IonItem className="item-select" color="white">
            <IonLabel color="grey">{labels[this.props.locale]['MACHINE_NAME']}</IonLabel>
            <IonSelect name="machineId"
              value={this.props.machine ? this.props.machine.id : undefined} 
              onIonChange={(e) => this.selectMachine((e.target as HTMLSelectElement) ? (e.target as HTMLSelectElement).value : undefined)} slot="end" color="primary">
              { this.props.machines ? this.props.machines.map((machine, index) => {
                if(AppConfig.testDatasEnabled || machine.name !== 'Test')
                  return <IonSelectOption key={index} value={machine.id}>{machine.name}</IonSelectOption>;
                return null;
              }) : null}
            </IonSelect>
          </IonItem>

          <IonItem color="white">
            <div className="ion-margin-vertical">
              <IonLabel>{labels[this.props.locale]['NOT_IN_LIST_TITLE']}</IonLabel>
              <IonNote>{labels[this.props.locale]['NOT_IN_LIST_TEXT1']}</IonNote>
              <br/>
              <IonNote>{labels[this.props.locale]['NOT_IN_LIST_TEXT2']}</IonNote>
            </div>
            <IonButton slot="end" color="primary" onClick={this.props.onOpenStore}>{labels[this.props.locale]['NOT_IN_LIST_BUTTON']}</IonButton>
          </IonItem>
          

          <IonListHeader color="light">{labels[this.props.locale]['PREFS']}</IonListHeader>
          <IonItem className="item-select" color="white">
            <IonLabel color="grey">{labels[this.props.locale]['LOCALE']}</IonLabel>
            <IonNote slot="end">
              { this.props.locales.map((locale, index) => 
                <IonButton key={index} color="primary" disabled={locale.disabled} onClick={() => this.props.onSelectLocale(locale)}>{locale.label}</IonButton> 
              )}
            </IonNote>
          </IonItem>
        </IonList>
      </div>
    );
  }
}

export default FormParams;
