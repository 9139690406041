import MocksService          from '../services/Mocks';
import {AppConfig}           from '../services/AppConfig';
import { Plugins }           from "@capacitor/core";

let SocialSharingService = {

  isEnabled : AppConfig.SocialSharingEnabled,
  isMock: AppConfig.socialSharing_isMock,


  share : (title,text,url,dialogTitle) => {
    const d = {
      title: title,
      text: text,
      url: url,
      dialogTitle: dialogTitle
    };
    if(SocialSharingService.isMock){
      MocksService.socialSharing.share(d);
    }else{
      Plugins.Share.share(d);
    }
  }

};
export default SocialSharingService;